import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { z } from "zod"
import { gql } from "~/__generated__"
import { signInTokenPath, signUpPath } from "~/common/paths"
import { todo } from "~/common/todo"
import { useSafeMutation } from "~/common/use-safe-mutation"
import linkIcon from "~/images/link-icon"
import { Button } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Input } from "~/ui/input"
import { Link } from "~/ui/link"
import { AccessLegalLinks } from "./access-legal-links"
import { StyledFormMessage } from "~/forms/styled-form-message"

const mutation = gql(`
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input) {
      sessionId
    }
  }
`)

export const TOS_URL = todo("https://example.com/tos")
export const PRIVACY_POLICY_URL = todo("https://example.com/privacy-policy")

const formSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: "Enter an email" })
    .email({ message: "Email is invalid" }),
})

export const SignInScreen = () => {
  const [sendMagicLink, { loading }] = useSafeMutation(mutation)
  const [searchParams] = useSearchParams()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: searchParams.get("email") || "",
    },
  })
  const navigate = useNavigate()

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data } = await sendMagicLink({
      variables: {
        input: {
          email: values.email,
        },
      },
    })

    if (data?.sendMagicLink.sessionId) {
      navigate(signInTokenPath({ sessionId: data.sendMagicLink.sessionId }))
    } else if (data?.sendMagicLink) {
      form.setError("email", {
        type: "not-found",
      })
    } else {
      form.setError("email", { message: "Error Sending Magic Link" })
    }
  }

  return (
    <div className="grid gap-8">
      <div className="text-center">
        Provide your email and we will send you a magic link to sign in.
      </div>

      <Card>
        <CardContent className="grid gap-4">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid gap-4 pt-4"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormItem className="space-y-4">
                    <StyledFormMessage>
                      {fieldState.error?.type === "not-found" ? (
                        <>
                          Email is invalid or not recognized. Try another email
                          or <Link to={signUpPath({})}>Sign Up</Link>.
                        </>
                      ) : null}
                    </StyledFormMessage>
                    <FormLabel className="sr-only">Email Address</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter email address" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <div className="flex justify-center">
                <Button type="submit" className="gap-2" disabled={loading}>
                  Get Magic Link <img {...linkIcon} alt="" />
                </Button>
              </div>
            </form>
          </Form>
          <AccessLegalLinks />
        </CardContent>
      </Card>
      <div className="text-sm text-center">
        Not a member yet?{" "}
        <a href={todo("/todo")} className="underline underline-offset-2">
          Sign Up
        </a>
      </div>
    </div>
  )
}
