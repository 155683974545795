import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { formatPercentage } from "~/common/percentage-formattingt"
import { calculatePercent } from "~/ui/percent"
import { metricsFormSchema } from "../../campaign-metrics-screen"
import { CampaignTotals } from "../campaign-totals"

export const CampaignEventTotals: React.FC<{
  form: UseFormReturn<z.infer<typeof metricsFormSchema>>
}> = ({ form }) => {
  const { watch } = form
  const campaignDeliverables = watch("deliverables")
  const totalRegistrants = campaignDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.registrantsMetric || "0"),
    0
  )
  const totalLiveAttendees = campaignDeliverables.reduce(
    (sum, deliverable) =>
      sum + parseInt(deliverable.liveAttendeesMetric || "0"),
    0
  )
  const totalOpens = campaignDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.opensMetric || "0"),
    0
  )
  const totalClicks = campaignDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.totalClicksMetric || "0"),
    0
  )
  const averageClickThroughRate = calculatePercent(totalClicks, totalOpens)

  return (
    <CampaignTotals
      totals={[
        { value: totalRegistrants, label: "Total Registrants" },
        { value: totalLiveAttendees, label: "Total Live Attendees" },
        { value: totalOpens, label: "Total Opens" },
        { value: totalClicks, label: "Total Clicks" },
        {
          value: formatPercentage(averageClickThroughRate),
          label: "Average CTR",
        },
      ]}
    />
  )
}
