import { Label } from "@radix-ui/react-label"
import copy from "copy-to-clipboard"
import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { CampaignDeliverableDetailFragmentFragment } from "~/__generated__/graphql"
import { formSchema } from "~/campaigns/campaign-deliverable-briefs"
import { Section, SectionLabel } from "~/campaigns/section"
import { Button } from "~/ui/button"
import { Input } from "~/ui/input"
import Text from "~/ui/typography"
import clipboardIcon from "~/images/icons/clipboard"
import { ButtonLink } from "~/ui/link"
import { Textarea } from "~/ui/textarea"
import { cn } from "~/common/cn"

type BriefProps = {
  form: UseFormReturn<z.infer<typeof formSchema>>
  campaignDeliverable: CampaignDeliverableDetailFragmentFragment
}

export const Brief = ({ form, campaignDeliverable }: BriefProps) => {
  const MAX_TRACKING_URLS = 2
  const trackingUrls = form.watch().trackingUrls

  return (
    <Section className="m b-6 flex-col space-y-4" variant="bordered">
      <SectionLabel variant="body-18-medium">Deliverable Brief</SectionLabel>
      {trackingUrls.map((url, index) => (
        <div className="flex flex-col gap-1">
          <Label className="flex items-center">
            <Text variant="body-14">Tracking URL* (UTM Parameters)</Text>
          </Label>
          <div className="flex items-center gap-2">
            <Input
              key={index}
              className={cn("max-w-xl", {
                "border border-primary":
                  form.formState.errors.trackingUrls && url.trim() === "",
              })}
              disabled={!campaignDeliverable.canUpdateBrief.value}
              {...form.register(`trackingUrls.${index}`)}
            />
            <Button
              variant="cardControl"
              className="flex items-center gap-2"
              onClick={(e) => {
                e.preventDefault()
                copy(url)
              }}
            >
              <img {...clipboardIcon} alt="" /> Copy to Clipboard
            </Button>
          </div>
        </div>
      ))}
      <div>
        {form.formState.errors.trackingUrls && (
          <div className="text-sm font-medium text-destructive">
            {form.formState.errors.trackingUrls.message}
          </div>
        )}
        {trackingUrls.length < MAX_TRACKING_URLS && (
          <ButtonLink
            type="button"
            onClick={() => form.setValue("trackingUrls", [...trackingUrls, ""])}
            variant="add"
          >
            Add Another
          </ButtonLink>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <Label className="flex items-center">
          <Text variant="body-14">Key Direction</Text>
        </Label>
        <Textarea
          {...form.register("keyDirection")}
          className="max-w-xl"
          disabled={!campaignDeliverable.canUpdateBrief.value}
        />
      </div>
    </Section>
  )
}
