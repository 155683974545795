import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { InputHTMLAttributes } from "react"
import { cn } from "~/common/cn"
import { FilterButton, FilterButtonProps } from "~/ui/filter-button"
import { Input } from "~/ui/input"

export const FilterField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  ...rest
}: {
  control: Control<TFieldValues> | undefined
  name: TName
} & Omit<FilterButtonProps, "value" | "onChange"> &
  InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FilterButton
          {...rest}
          ref={field.ref}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}

export const ZFilterField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  labelClassName,
}: {
  label: string
  labelClassName?: string
  control: Control<TFieldValues> | undefined
  name: TName
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className={cn(labelClassName)}>{label}</FormLabel>
          <FormControl>
            <Input {...field} />
          </FormControl>
          <FormMessage className="text-xs" />
        </FormItem>
      )}
    />
  )
}
