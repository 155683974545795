// app/frontend/products/products-screen.tsx

import { useLazyQuery, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Params } from "static-path"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  ProductBriefsTableRowFragment,
  ProductBriefStatus,
  Role,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import {
  companyProductsDetailPath,
  companyProductsEditPath,
  newCompanyProductsPath,
} from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { countNonDefaults } from "~/common/zod-changed-defaults"
import { URLParamsSerializer } from "~/common/zod-search-params"
import { CompanyLogo } from "~/companies/company-logo"
import arrowRight from "~/images/icons/arrow-right"
import threeDotIcon from "~/images/icons/three-dot-icon"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { ArchiveProductModal } from "~/products/archive-product-modal"
import { ActiveFilters } from "~/search/active-filters"
import { NoResults } from "~/search/no-results"
import { SearchInput } from "~/search/search-input"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { FilterButton } from "~/ui/filter-button"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Heading } from "~/ui/heading"
import { InfiniteLoadMore } from "~/ui/infinite-load-more"
import { Link } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import {
  StyledDropdown,
  StyledDropdownInner,
  StyledDropdownItemWrapper,
} from "~/ui/styled-dropdown"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { useToast } from "~/ui/use-toast"

gql(/* GraphQL */ `
  fragment ProductBriefsTableRow on ProductBrief {
    id
    name
    status
    company {
      id
      name
      ...CompanyLogo
    }
  }
`)

const formSchema = z.object({
  search: z.string(),
  companies: z.array(z.string()),
  statuses: z.array(z.nativeEnum(ProductBriefStatus)),
})

const formDefaults = {
  search: "",
  companies: [],
  statuses: [],
}

const serializer = new URLParamsSerializer(formSchema, formDefaults)

export const ProductsScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useParams() as Params<typeof companyProductsDetailPath.pattern>
  const { viewer } = useViewer()
  const navigate = useNavigate()
  const [archiveProductModalProduct, setArchiveProductModalProduct] =
    useState<ProductBriefsTableRowFragment>()

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: serializer.deserialize(searchParams),
  })

  const { watch, setValue } = form
  const formData = watch()

  useEffect(() => {
    const newSearchParams = serializer.serialize(formData)
    if (newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [formData, searchParams, setSearchParams])

  const activeFiltersCount = countNonDefaults(
    formSchema,
    formDefaults,
    formData
  )

  const [debouncedSearch] = useDebounce(formData.search, 200)

  const { data, previousData, loading, error, fetchMore } = useQuery(
    searchProductsQuery,
    {
      variables: {
        filter: {
          search: debouncedSearch,
          companies:
            formData.companies.length > 0 ? formData.companies : undefined,
          statuses:
            formData.statuses.length > 0
              ? formData.statuses
              : [ProductBriefStatus.Active],
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  const clearSearch = () => {
    setValue("search", "")
    setValue("companies", [])
    setValue("statuses", [])
  }

  const currentData = data || previousData
  const products =
    currentData?.productBriefs.edges.map((edge) => edge.node) ?? []
  const pageInfo = currentData?.productBriefs.pageInfo

  const [companyQuery, setCompanyQuery] = useState("")
  const [debouncedCompanySearch] = useDebounce(companyQuery, 200)

  const [sendQuery, { data: companiesData, loading: companiesLoading }] =
    useLazyQuery(companiesQuery, {
      variables: {
        search: debouncedCompanySearch,
      },
    })

  const companies =
    companiesData?.companies.edges.map((edge) => edge.node) ?? []

  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }))

  const statusOptions = [
    { label: "Active", value: "ACTIVE" },
    { label: "Archived", value: "ARCHIVED" },
  ]

  const activeSearch =
    activeFiltersCount > 0 ||
    formData.search.length > 0 ||
    formData.statuses.length > 0

  const statusToHumanReadable = (status: ProductBriefStatus) => {
    switch (status) {
      case ProductBriefStatus.Active:
        return "Active"
      case ProductBriefStatus.Archived:
        return "Archived"
      default:
        const exhaustiveCheck: never = status
        throw new Error(`Unhandled case: ${exhaustiveCheck}`)
    }
  }

  const companyNameFromId = (id: string) => {
    const company = companies.find((company) => company?.id === id)
    return company?.name
  }

  const canCreateProducts =
    viewer.role === Role.Client ||
    viewer.role === Role.WorkweekAdmin ||
    viewer.role === Role.WorkweekTeam

  return (
    <TablePageLayout>
      <div className="px-4 space-y-8">
        <div className="flex items-center gap-2 justify-between">
          <Form {...form}>
            <form className="flex items-center gap-2">
              <FormField
                control={form.control}
                name="search"
                render={({ field }) => (
                  <SearchInput placeholder="Search Products" {...field} />
                )}
              />

              <FormField
                control={form.control}
                name="companies"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormLabel className="sr-only">Filter by Company</FormLabel>
                    <FormControl>
                      <FilterButton
                        typeAhead
                        isLoading={companiesLoading}
                        onOpen={() => sendQuery()}
                        text="Filter by Company"
                        options={companyOptions}
                        {...field}
                        onQueryChange={(query) => {
                          setCompanyQuery(query)
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="statuses"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormLabel className="sr-only">Filter by Status</FormLabel>
                    <FormControl>
                      <FilterButton
                        text="Filter by Status"
                        options={statusOptions}
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </form>
          </Form>
          {canCreateProducts && (
            <LinkButton
              size="sm"
              to={newCompanyProductsPath({
                slug: params.slug,
              })}
            >
              Create New Product
            </LinkButton>
          )}
        </div>

        {activeFiltersCount > 0 && (
          <div className="bg-gray-50 rounded-lg p-5 py-4 flex items-center gap-6">
            <Heading
              title="Filters applied"
              count={activeFiltersCount}
              className="mb-0"
            />

            {formData.companies.length > 0 && (
              <ActiveFilters
                label="Company"
                values={formData.companies
                  .map((c) => companyNameFromId(c))
                  .join(", ")}
                onClear={() => setValue("companies", [])}
              />
            )}

            {formData.statuses.length > 0 && (
              <ActiveFilters
                label="Status"
                values={formData.statuses
                  .map((status) => statusToHumanReadable(status))
                  .join(", ")}
                onClear={() => setValue("statuses", [])}
              />
            )}
          </div>
        )}

        <Heading title="Products" count={products.length} />

        {error ? <GraphqlError error={error} /> : null}

        {products.length > 0 && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Product Name</TableHead>
                <TableHead>Company</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="w-24 text-right">Actions</TableHead>
                <TableHead className="w-[68px] text-right"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.id}
                  onClick={() =>
                    navigate(
                      companyProductsDetailPath({
                        slug: params.slug,
                        productId: product.id,
                      })
                    )
                  }
                >
                  <TableCell>{product.name}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-4">
                      <CompanyLogo company={product.company} />
                      {product.company.name}
                    </div>
                  </TableCell>
                  <TableCell>{statusToHumanReadable(product.status)}</TableCell>
                  <TableCell className="text-right">
                    <ActionsMenu
                      product={product}
                      companySlug={params.slug}
                      onArchiveClick={() =>
                        setArchiveProductModalProduct(product)
                      }
                    />
                  </TableCell>
                  <TableCell className="text-right pe-4">
                    <LinkButton
                      to={companyProductsDetailPath({
                        slug: params.slug,
                        productId: product.id,
                      })}
                      variant="ghost"
                      size="icon"
                    >
                      <img {...arrowRight} className="inline-block" alt="" />
                      <span className="sr-only">View Campaign</span>
                    </LinkButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        <InfiniteLoadMore
          onEndReached={() => {
            if (pageInfo?.endCursor) {
              fetchMore({
                variables: { productsCursor: pageInfo.endCursor },
              })
            }
          }}
          canLoadMore={!loading && (pageInfo?.hasNextPage ?? false)}
          loadingText="Loading more products..."
          loading={loading && products.length > 0}
        />

        {!loading && !error && products.length === 0 && (
          <NoResults
            title="No Products Found"
            description={
              activeSearch
                ? "Clear your search results to view all products"
                : "We couldn't find any products"
            }
            onClearSearch={activeSearch ? clearSearch : undefined}
          />
        )}

        <ArchiveProductModal
          onClose={() => setArchiveProductModalProduct(undefined)}
          isOpen={archiveProductModalProduct !== undefined}
          product={archiveProductModalProduct}
        />
      </div>
    </TablePageLayout>
  )
}

const searchProductsQuery = gql(/* GraphQL */ `
  query SearchProducts(
    $productsCursor: String
    $filter: ProductBriefFilterInput
  ) {
    productBriefs(filter: $filter, after: $productsCursor, first: 20) {
      edges {
        node {
          id
          ...ProductBriefsTableRow
        }
      }
      pageInfo {
        ...Pagination
      }
    }
  }
`)

const companiesQuery = gql(/* GraphQL */ `
  query Companies($search: String, $first: Int = 10) {
    companies(search: $search, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

const ActionsMenu = ({
  product,
  companySlug,
  onArchiveClick,
}: {
  product: ProductBriefsTableRowFragment
  companySlug: string
  onArchiveClick: () => void
}) => {
  const [runMutation] = useSafeMutation(RESTORE_PRODUCT_MUTATION)
  const { toast } = useToast()

  const restoreProduct = async () => {
    const { errors } = await runMutation({
      variables: { input: { id: product.id } },
    })

    if (errors) {
      toast({ title: "Couldn't restore product", variant: "destructive" })
    } else {
      toast({ title: "Product has been restored" })
    }
  }

  const isActive = product.status === ProductBriefStatus.Active
  const isArchived = product.status === ProductBriefStatus.Archived

  return (
    <StyledDropdown
      trigger={
        <div className="h-6 flex items-center hover:bg-gray-100 rounded px-2">
          <img {...threeDotIcon} alt="Actions icon" />
        </div>
      }
    >
      {isActive && (
        <StyledDropdownItemWrapper asChild>
          <Button
            variant="ghost"
            size="sm"
            className="justify-start text-xs-plus h-7"
            onClick={onArchiveClick}
          >
            <div className="pl-4">Archive</div>
          </Button>
        </StyledDropdownItemWrapper>
      )}
      {isArchived && (
        <StyledDropdownItemWrapper asChild>
          <Button
            variant="ghost"
            size="sm"
            className="justify-start text-xs-plus h-7"
            onClick={restoreProduct}
          >
            <div className="pl-4">Restore</div>
          </Button>
        </StyledDropdownItemWrapper>
      )}
      <StyledDropdownItemWrapper asChild>
        <Link
          to={companyProductsEditPath({
            slug: companySlug,
            productId: product.id,
          })}
          variant="unstyled"
        >
          <StyledDropdownInner title="Edit" className="text-xs-plus" />
        </Link>
      </StyledDropdownItemWrapper>
    </StyledDropdown>
  )
}

const RESTORE_PRODUCT_MUTATION = gql(`
  mutation RestoreProduct($input: ProductBriefRestoreInput!) {
    productBriefRestore(input: $input) {
      productBrief {
        id
        status
      }
    }
  }
`)
