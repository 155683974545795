import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { CampaignDeliverableDetailFragmentFragment } from "~/__generated__/graphql"
import { formSchema } from "~/campaigns/campaign-deliverable-briefs"
import { Section, SectionLabel } from "~/campaigns/section"
import { cn } from "~/common/cn"
import { MinimalTiptapEditor } from "~/tiptap"
import { Input } from "~/ui/input"
import { Label } from "~/ui/label"
import Text from "~/ui/typography"

type DeliverablesProps = {
  form: UseFormReturn<z.infer<typeof formSchema>>
  campaignDeliverable: CampaignDeliverableDetailFragmentFragment
}

export const Deliverables = ({
  form,
  campaignDeliverable,
}: DeliverablesProps) => {
  const deliverableContent = form.watch("deliverableContent")

  return (
    <Section variant="bordered" className="mb-6 flex-col space-y-4">
      <SectionLabel variant="body-18-medium">Deliverables</SectionLabel>

      <div className="space-y-8">
        {campaignDeliverable.rejectionFeedback && (
          <div className="border border-red-600 rounded-lg p-6">
            <Text variant="body-12" className="text-red-600 block mb-2">
              Draft Feedback
            </Text>
            <Text variant="body-14">
              {campaignDeliverable.rejectionFeedback}
            </Text>
          </div>
        )}

        <div className="flex flex-col gap-1">
          <MinimalTiptapEditor
            value={
              deliverableContent ? JSON.parse(deliverableContent) : undefined
            }
            onValueChange={(value) => {
              form.setValue("deliverableContent", JSON.stringify(value))
            }}
            disabled={!campaignDeliverable.canUpdateDeliverables.value}
          />
        </div>
      </div>
      {(campaignDeliverable.placement === "Primary" ||
        campaignDeliverable.placement === "Secondary") && (
        <>
          <div className="flex flex-col gap-1">
            <Label className="flex items-center">
              <Text variant="body-14">Call to Action (Button)*</Text>
            </Label>
            <Input
              {...form.register("ctaButton")}
              className={cn("max-w-xl", {
                "border border-primary": form.formState.errors.ctaButton,
              })}
              disabled={!campaignDeliverable.canUpdateDeliverables.value}
            />
            {form.formState.errors.ctaButton && (
              <div className="text-sm font-medium text-destructive">
                {form.formState.errors.ctaButton.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <Label className="flex items-center">
              <Text variant="body-14">Call to Action (URL)*</Text>
            </Label>
            <Input
              {...form.register("ctaUrl")}
              className={cn("max-w-xl", {
                "border border-primary": form.formState.errors.ctaUrl,
              })}
              disabled={!campaignDeliverable.canUpdateDeliverables.value}
            />
            {form.formState.errors.ctaUrl && (
              <div className="text-sm font-medium text-destructive">
                {form.formState.errors.ctaUrl.message}
              </div>
            )}
          </div>
        </>
      )}
    </Section>
  )
}
