import { QueryResult } from "@apollo/client"
import { Link, useParams } from "react-router-dom"
import { MyRoomsQuery, Room_MessageListFragment } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/viewer-context"
import { cn } from "~/common/cn"
import { dmPath, roomPath } from "~/common/paths"
import { postDateDisplay } from "~/feed/postDateDisplay"
import MessageCompose from "~/images/icons/message-compose.svg?react"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { StyledDropdown, StyledDropdownItem } from "~/ui/StyledDropdown"
import { MessageSectionHeader } from "./MessageSectionHeader"
import { roomDisplayName } from "./utils"

export const RoomsPane = ({
  openComposeModal,
  openNewRoomModal,
  roomsResult,
  orderedRooms,
}: {
  openComposeModal: () => void
  openNewRoomModal: () => void
  roomsResult: QueryResult<MyRoomsQuery>
  orderedRooms: Room_MessageListFragment[]
}) => {
  const { currentUser } = useCurrentUser()

  return (
    <div className="flex-1-1-auto overflow-y-scroll relative">
      <MessageSectionHeader className="">
        <AvatarWithFallback
          user={currentUser}
          size="header"
          className="mr-4 hidden xl:block"
        />
        <div className="flex-1 text-xs font-semibold">{currentUser.name}</div>

        <StyledDropdown trigger={<MessageCompose />}>
          <StyledDropdownItem title="Send a DM" onClick={openComposeModal} />
          <StyledDropdownItem
            title="Create a group chat"
            onClick={openNewRoomModal}
          />
        </StyledDropdown>
      </MessageSectionHeader>

      <div className="p-4">
        {roomsResult.error ? (
          <Error message="Error loading conversations." />
        ) : roomsResult.loading && orderedRooms.length === 0 ? (
          <LoadingIndicator />
        ) : roomsResult.data ? (
          <div className="flex flex-col gap-4">
            {orderedRooms.map((room) => (
              <RoomEntry room={room} key={room.id} />
            ))}
            <InfiniteLoadMore
              onEndReached={() =>
                roomsResult.fetchMore({
                  variables: {
                    roomsCursor: roomsResult.data?.myRooms.pageInfo.endCursor,
                  },
                })
              }
              canLoadMore={
                !roomsResult.loading &&
                roomsResult.data.myRooms.pageInfo.hasNextPage
              }
              loadingText="Loading posts..."
              loading={roomsResult.loading && orderedRooms.length > 0}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const RoomEntry = ({ room }: { room: Room_MessageListFragment }) => {
  const { currentUser } = useCurrentUser()
  const otherUser = room.dmOtherUser
  const latestPost = room.latestPost

  const { otherUserId, roomId } = useParams()
  const isSelected =
    (otherUserId && otherUserId === otherUser?.id) ||
    (roomId && room.id === roomId)

  const path = otherUser?.id
    ? dmPath({ otherUserId: otherUser.id })
    : roomPath({ roomId: room.id })

  return (
    <Link
      to={path}
      className={cn("flex items-center tracking-wide -m-2 p-2 px-4 -mx-4", {
        "bg-gray-f9": isSelected,
      })}
    >
      <AvatarWithFallback
        hasIndicator={room.hasUnreadPosts}
        size="header"
        user={
          otherUser || {
            id: room.id,
            admin: false,
            firstName: "",
            lastName: "",
            name: "",
          }
        }
        className="mr-3"
        textOverride={otherUser ? undefined : room.memberCount.toString()}
      />
      <div>
        <div className="font-medium text-xs mb-1">{roomDisplayName(room)}</div>
        {latestPost && (
          <div className="text-2xs">
            {latestPost.user.id === currentUser.id
              ? "You replied"
              : "Sent a message"}
            &nbsp;&middot;&nbsp;
            {postDateDisplay(latestPost.createdAt)}
          </div>
        )}
      </div>
    </Link>
  )
}
