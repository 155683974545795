import { useState } from "react"
import { Button } from "~/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import FilterIcon from "~/images/icons/filter.svg?react"
import { cn } from "~/common/cn"
import React from "react"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/ui/command"
import { CommandLoading } from "cmdk"

type OptionType = {
  label: string
  value: string
}

export type FilterButtonProps = {
  text: string
  options: OptionType[]
  value: string[]
  onChange: (value: string[]) => void
  onOpen?: () => void
  isLoading?: boolean
  typeAhead?: boolean
  onQueryChange?: (query: string) => void
}

/**
 * a button that opens a popover with a list of options that can be selected
 */
export const FilterButton = React.forwardRef(
  (
    {
      text,
      options,
      value,
      onChange,
      onOpen,
      isLoading = false,
      typeAhead = false,
      onQueryChange,
    }: FilterButtonProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [open, setOpen] = useState(false)

    const onOpenChange = () => {
      // if open is false, it means we're opening the popover and we want to call the onOpen callback
      if (!open && onOpen) {
        onOpen()
      }
      setOpen(!open)
    }

    const onSelect = (selectedValue: string) => {
      if (value.includes(selectedValue)) {
        onChange(value.filter((v) => v !== selectedValue))
      } else {
        onChange([...value, selectedValue])
      }
    }

    return (
      <Popover open={open} onOpenChange={onOpenChange}>
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            className={cn("h-8 font-medium text-xs px-3 shadow-otp", {
              "bg-black text-white border-transparent hover:bg-black hover:text-white":
                open,
            })}
          >
            <FilterIcon className="mr-2" /> {text}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-56 p-0 flex flex-col space-y-2">
          <Command>
            {typeAhead && (
              <CommandInput
                placeholder="Search..."
                className="h-9 border-0 focus:border-transparent focus:ring-0 text-xs-plus"
                onValueChange={onQueryChange}
              />
            )}
            <CommandEmpty className="text-sm p-2">Nothing here...</CommandEmpty>
            {isLoading && options.length === 0 && (
              <CommandLoading>
                <LoadingIndicatorCentered />
              </CommandLoading>
            )}
            <CommandGroup>
              <CommandList>
                {options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={() => onSelect(option.value)}
                    keywords={[option.label]}
                    className="flex items-center space-x-3 text-xs-plus"
                  >
                    <input
                      type="checkbox"
                      checked={value.includes(option.value)}
                      onChange={() => onSelect(option.value)}
                      className="bg-white border-gray-200 rounded-sm h-4 w-4 text-black focus:ring-black"
                      ref={ref}
                    />

                    <span>{option.label}</span>
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    )
  }
)
