import { CampaignDeliverableStatus } from "~/__generated__/graphql"
import { campaignDeliverableEditMetricsPath } from "~/common/paths"
import { LinkButton } from "~/ui/link-button"
import Text from "~/ui/typography"
import { Subsection } from "./subsection"
import { SubsectionBody } from "./subsection-body"
import { SubsectionTitle } from "./subsection-title"

export const CampaignDeliverableMetrics: React.FC<{
  campaignDeliverable: any
}> = ({ campaignDeliverable }) => {
  if (campaignDeliverable.status !== CampaignDeliverableStatus.Published) {
    return null
  }

  const metricsAvailable = true

  const metrics = {
    emailRecipients: 37619,
    opens: 20506,
    clicks: 329,
    realClicks: 159,
    notes: campaignDeliverable.metricNotes ?? "",
  }

  const openRate = (metrics.opens / metrics.emailRecipients) * 100
  const clickThroughRate = (metrics.clicks / metrics.emailRecipients) * 100

  return (
    <div>
      <div className="flex items-center justify-between">
        <Text variant="body-18-medium">Metrics</Text>
        {campaignDeliverable.canEditMetrics.value && (
          <LinkButton
            variant="linkMuted"
            className="uppercase font-medium"
            to={campaignDeliverableEditMetricsPath({
              campaignId: campaignDeliverable.campaign.id,
              deliverableId: campaignDeliverable.id,
            })}
          >
            Edit metrics
          </LinkButton>
        )}
      </div>

      {metricsAvailable && (
        <div className="grid gap-4 pt-4">
          <Subsection>
            <SubsectionTitle>Email Recipients</SubsectionTitle>
            <SubsectionBody>
              {metrics.emailRecipients.toLocaleString()}
            </SubsectionBody>
          </Subsection>

          <Subsection>
            <SubsectionTitle>Opens</SubsectionTitle>
            <SubsectionBody>{metrics.opens.toLocaleString()}</SubsectionBody>
          </Subsection>

          <Subsection>
            <SubsectionTitle>Open Rate</SubsectionTitle>
            <SubsectionBody>{openRate.toFixed(2)}%</SubsectionBody>
          </Subsection>

          <Subsection>
            <SubsectionTitle>Clicks</SubsectionTitle>
            <SubsectionBody>{metrics.clicks.toLocaleString()}</SubsectionBody>
          </Subsection>

          <Subsection>
            <SubsectionTitle>Click Through Rate (CTR)</SubsectionTitle>
            <SubsectionBody>{clickThroughRate.toFixed(2)}%</SubsectionBody>
          </Subsection>

          <Subsection>
            <SubsectionTitle>Notes</SubsectionTitle>
            <SubsectionBody>
              {metrics.notes ? metrics.notes : "–"}
            </SubsectionBody>
          </Subsection>
        </div>
      )}

      {!metricsAvailable && (
        <div className="h-64 flex flex-col justify-center">
          <div className="flex flex-col items-center justify-center">
            <Text variant="body-24" className="mb-4">
              Metrics Unavailable
            </Text>
            <Text variant="body-14">Metrics are currently unavailable.</Text>
            <Text variant="body-14">Please check back for more details.</Text>
          </div>
        </div>
      )}
    </div>
  )
}
