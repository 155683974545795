import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "~/common/cn"

export const textVariants = cva("", {
  variants: {
    variant: {
      default:
        "uppercase tracking-wide font-bold text-xs flex items-center gap-2 leading-tight",
      lg: "tracking-wide font-medium text-lg flex items-center gap-2 leading-tight",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

type HeadingVariants = VariantProps<typeof textVariants>

type HeadingProps = {
  title: string
  count?: number
  className?: string
}

type HeadingPropsWithVariants = HeadingProps & HeadingVariants

export const Heading = ({
  title,
  count,
  variant,
  className,
}: HeadingPropsWithVariants) => {
  return (
    <div className="flex items-center gap-1">
      <div className={cn(textVariants({ variant }), className)}>{title}</div>
      {count && (
        <div className="border rounded px-1.5 py-[2px] font-normal text-xs">
          {count}
        </div>
      )}
    </div>
  )
}
