import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  addWeeks,
  endOfISOWeek,
  format,
  formatISO,
  parseISO,
  startOfISOWeek,
} from "date-fns"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CampaignDeliverableRowFragment,
  CampaignDeliverableStatus,
} from "~/__generated__/graphql"
import { CampaignDeliverableStatusLabel } from "~/campaigns/campaign-deliverable-status"
import { formatDate } from "~/common/date-formatting"
import { campaignDeliverablePath } from "~/common/paths"
import { URLParamsSerializer } from "~/common/zod-search-params"
import { CompanyLogo } from "~/companies/company-logo"
import { SelectField } from "~/fields/select-field"
import arrowRight from "~/images/icons/arrow-right"
import calendar from "~/images/icons/calendar"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { Form } from "~/ui/form"
import { Invariant } from "~/ui/invariant"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import Text from "~/ui/typography"
import { ActionItems } from "./action-items"

const query = gql(/* GraphQL */ `
  query DeliverablesDashboardQuery(
    $filter: CampaignDeliverablesFilterInput
    $after: String
  ) {
    campaignDeliverables(first: 50, filter: $filter, after: $after) {
      pageInfo {
        ...Pagination
      }
      edges {
        node {
          id
          ...CampaignDeliverableRow
        }
      }
    }
  }
`)

enum Period {
  weeks_2 = "weeks_2",
  weeks_4 = "weeks_4",
  upcoming = "upcoming",
}

const periodLables: Record<Period, string> = {
  [Period.weeks_2]: "2 Weeks",
  [Period.weeks_4]: "4 Weeks",
  [Period.upcoming]: "Upcoming",
}

const formSchema = z.object({
  period: z.nativeEnum(Period),
})

const formDefaults: z.infer<typeof formSchema> = {
  period: Period.weeks_2,
}
const serializer = new URLParamsSerializer(formSchema, formDefaults)

let now = new Date()
let startOfWeek = startOfISOWeek(now)

function maxDate(period: Period): Date | undefined {
  switch (period) {
    case Period.weeks_2:
      return endOfISOWeek(addWeeks(startOfWeek, 1))
    case Period.weeks_4:
      return endOfISOWeek(addWeeks(startOfWeek, 3))
    case Period.upcoming:
      return
  }
}

export const DeliverablesDashboardScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: serializer.deserialize(searchParams),
  })

  const formData = form.watch()
  useEffect(() => {
    const newSearchParams = serializer.serialize(formData)
    if (newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [formData, searchParams, setSearchParams])

  const period = form.watch("period")
  const max = maxDate(period)

  const result = useQuery(query, {
    variables: {
      filter: {
        actionDate: {
          min: formatISO(startOfWeek, { representation: "date" }),
          ...(max && { max: formatISO(max, { representation: "date" }) }),
        },
      },
      after: null,
    },
  })

  const records = (
    result.data?.campaignDeliverables?.edges ??
    result.previousData?.campaignDeliverables?.edges ??
    []
  ).map((e) => e.node)

  return (
    <div className="grid grid-cols-[1fr_300px] flex-1">
      <div className="border-e">
        <div className="flex h-[60px]">
          <Text as="div" variant="mini-caps" className="p-6">
            Upcoming Deliverables
          </Text>
          <Form {...form}>
            <form className="flex items-center gap-2 ms-auto pe-6">
              <SelectField
                name="period"
                control={form.control}
                text={({ value }) => (
                  <div className="flex items-center gap-2 pr-2">
                    <img
                      alt=""
                      {...calendar}
                      className="inline align-baseline"
                    />{" "}
                    {periodLables[value as Period]}
                  </div>
                )}
                options={Object.entries(periodLables).map(([value, label]) => ({
                  value,
                  label,
                }))}
              />
            </form>
          </Form>
        </div>
        <div className="flex flex-1 overflow-auto">
          {result.error ? (
            <GraphqlError error={result.error} />
          ) : result.loading ? (
            <LoadingIndicatorCentered />
          ) : result.data ? (
            <div className="flex flex-col flex-1">
              <DeliverableTable campaignDeliverables={records} />
              {result.data.campaignDeliverables.pageInfo.hasNextPage ? (
                <div className="px-8 py-4">
                  <Button
                    onClick={() => {
                      invariant(result.data)
                      result.fetchMore({
                        variables: {
                          after:
                            result.data.campaignDeliverables.pageInfo.endCursor,
                        },
                      })
                    }}
                  >
                    Load more
                  </Button>
                </div>
              ) : null}
            </div>
          ) : (
            <Invariant />
          )}
        </div>
      </div>
      <div className="">
        <ActionItems />
      </div>
    </div>
  )
}

gql(/* GraphQL */ `
  fragment CampaignDeliverableRow on CampaignDeliverable {
    id
    deliverableName
    campaign {
      id
      campaignName
      company {
        id
        ...CompanyLogo
      }
    }
    status
    publishDateCanonical
    actionDate
    deliverableBriefDueDateCanonical
  }
`)

const DeliverableTable: React.FC<{
  campaignDeliverables: Array<CampaignDeliverableRowFragment>
}> = ({ campaignDeliverables }) => {
  const groupedByActionDateWeek = campaignDeliverables.reduce(
    (acc, deliverable) => {
      let week
      if (deliverable.actionDate) {
        week = formatISO(startOfISOWeek(new Date(deliverable.actionDate)), {
          representation: "date",
        })
      } else {
        week = "unknown"
      }

      acc[week] = [...(acc[week] ?? []), deliverable]
      return acc
    },
    {} as Record<string, CampaignDeliverableRowFragment[]>
  )

  if (Object.keys(groupedByActionDateWeek).length === 0) {
    return (
      <Text
        as="div"
        variant="subtext-10-bold"
        className="bg-gray-f9 py-3 px-[40px] flex flex-1 w-full border-t border-b"
      >
        None
      </Text>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        {Object.entries(groupedByActionDateWeek).map(([week, deliverables]) => (
          <div key={week} className="flex flex-col flex-1">
            <Text
              as="div"
              variant="subtext-10-bold"
              className="bg-gray-f9 py-3 px-[40px] flex flex-1 border-t border-b"
            >
              {week === "unknown" ? (
                <>Unknown Date</>
              ) : (
                <>Week of {format(parseISO(week), "MM/dd/yyyy")} </>
              )}
            </Text>
            <div className="p-8 flex flex-col flex-1 gap-2">
              {deliverables.map((deliverable) => (
                <DeliverableRow
                  key={deliverable.id}
                  deliverable={deliverable}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const DeliverableRow: React.FC<{
  deliverable: CampaignDeliverableRowFragment
}> = ({ deliverable }) => {
  return (
    <Link
      to={campaignDeliverablePath({
        campaignId: deliverable.campaign.id,
        deliverableId: deliverable.id,
      })}
      className="flex flex-1"
    >
      <div className="border rounded flex flex-1">
        {/* <div className="grid grid-cols-[24px,280px,442px,220px] gap-4 content-center"> */}
        <div className="grid grid-cols-[24px_minmax(140px,1fr)_minmax(442px,1fr)_minmax(300px,1fr)] gap-4 content-center items-center px-6">
          <div className="py-6">
            <CompanyLogo company={deliverable.campaign.company} size="24" />
          </div>
          <div>
            <Text variant="body-14-medium">
              {deliverable.campaign.company.name}
            </Text>
          </div>
          <div className="flex gap-4 items-center">
            <div>
              <Text variant="body-14">{deliverable.deliverableName}</Text>
            </div>
            <div className="ms-auto">
              <div className="border border-gray-d0 rounded px-2 py-1 whitespace-nowrap">
                <Text variant="body-14">
                  <CampaignDeliverableStatusLabel {...deliverable} />
                </Text>
              </div>
            </div>
          </div>
          <div className="text-right flex items-center justify-end">
            <Text variant="body-14">
              {deliverable.status === CampaignDeliverableStatus.BriefNeeded
                ? `Brief Due Date: ${
                    deliverable.deliverableBriefDueDateCanonical
                      ? formatDate(deliverable.deliverableBriefDueDateCanonical)
                      : "–"
                  }`
                : `Publish Date: ${
                    deliverable.publishDateCanonical
                      ? formatDate(deliverable.publishDateCanonical)
                      : "–"
                  }`}
            </Text>
            <Button variant="ghost" size="icon" className="ms-1">
              <img {...arrowRight} alt="" />
            </Button>
          </div>
        </div>
      </div>
    </Link>
  )
}
