import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link, useOutlet, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CreatorBrandStatus,
  CreatorBrandsTableRowFragment,
} from "~/__generated__/graphql"
import { creatorBrandsEditPath } from "~/common/paths"
import ArrowRightIcon from "~/images/icons/arrow-right.svg?react"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { ActiveFilters } from "~/search/active-filters"
import { NoResults } from "~/search/no-results"
import { SearchInput } from "~/search/search-input"
import { FilterButton } from "~/ui/filter-button"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Heading } from "~/ui/heading"
import { InfiniteLoadMore } from "~/ui/infinite-load-more"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { CreatorBrandImage } from "./creator-brand-image"

gql(/* GraphQL */ `
  fragment CreatorBrandsTableRow on CreatorBrand {
    id
    name
    status
    ctaColor
    image {
      id
      imgixUrl
    }
  }
`)

type CreatorBrandsTableProps = {
  creatorBrands: Array<CreatorBrandsTableRowFragment>
}

const CreatorBrandsTable = ({ creatorBrands }: CreatorBrandsTableProps) => {
  const statusToHumanReadable = (status: CreatorBrandStatus) => {
    switch (status) {
      case CreatorBrandStatus.Active:
        return "Active"
      case CreatorBrandStatus.Archived:
        return "Archived"
      default:
        const exhaustiveCheck: never = status
        throw new Error(`Unhandled case: ${exhaustiveCheck}`)
    }
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Creator Brand</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Actions</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {creatorBrands.map((brand) => (
          <TableRow key={brand.id}>
            <TableCell>
              <div className="flex items-center gap-2">
                <CreatorBrandImage {...brand} />
                {brand.name}
              </div>
            </TableCell>
            <TableCell>{statusToHumanReadable(brand.status)}</TableCell>
            <TableCell className="w-24">
              <Link
                to={creatorBrandsEditPath({
                  creatorBrandId: brand.id,
                })}
              >
                Edit
              </Link>
            </TableCell>
            <TableCell className="w-8">
              <ArrowRightIcon />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
export const CreatorBrandsScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const statusOptions: Array<{ label: string; value: CreatorBrandStatus }> = [
    { label: "Active", value: CreatorBrandStatus.Active },
    { label: "Archived", value: CreatorBrandStatus.Archived },
  ]

  const formSchema = z.object({
    search: z.string(),
    statuses: z.array(z.nativeEnum(CreatorBrandStatus)),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formSchema.parse({
      search: searchParams.get("search") ?? "",
      statuses:
        searchParams.getAll("status").length > 0
          ? searchParams.getAll("status")
          : [CreatorBrandStatus.Active],
    }),
  })

  const { watch, setValue } = form

  const formData = watch()

  useEffect(() => {
    const newSearchParams = new URLSearchParams()

    if (formData.search) {
      newSearchParams.set("search", formData.search)
    }

    for (let status of formData.statuses) {
      newSearchParams.append("status", status)
    }

    setSearchParams(newSearchParams, { replace: true })
  }, [formData.statuses, formData.search, setSearchParams])

  let activeFiltersCount = 0
  if (formData.statuses.length > 0) {
    activeFiltersCount += 1
  }

  const [debouncedSearch] = useDebounce(formData.search, 200)

  const { data, previousData, loading, error, fetchMore } = useQuery(
    CREATOR_BRANDS_QUERY_DOCUMENT,
    {
      variables: {
        filters: {
          search: debouncedSearch,
          statuses:
            formData.statuses.length > 0 ? formData.statuses : undefined,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  const clearSearch = () => {
    setValue("search", "")
    setValue("statuses", [])
  }

  const currentData = data || previousData
  const creatorBrands =
    currentData?.creatorBrands.edges.map((edge) => edge.node) ?? []
  const pageInfo = currentData?.creatorBrands.pageInfo
  const activeSearch = activeFiltersCount > 0 || formData.search.length > 0

  const outlet = useOutlet()

  return (
    <TablePageLayout rightSideSlot={outlet ?? undefined}>
      <div className="flex items-center gap-2">
        <Form {...form}>
          <form className="flex items-center gap-2">
            <FormField
              control={form.control}
              name="search"
              render={({ field }) => (
                <SearchInput placeholder="Search Creator Brands" {...field} />
              )}
            />

            <FormField
              control={form.control}
              name="statuses"
              render={({ field }) => (
                <FormItem className="space-y-0">
                  <FormLabel className="sr-only">Filter by Status</FormLabel>
                  <FormControl>
                    <FilterButton
                      text="Filter by Status"
                      options={statusOptions}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>

      {activeFiltersCount > 0 && (
        <div className="bg-gray-50 rounded-lg p-5 py-4 flex items-center gap-6">
          <Heading
            title="Filters applied"
            count={activeFiltersCount}
            className="mb-0"
          />

          {formData.statuses.length > 0 && (
            <ActiveFilters
              label="Status"
              values={formData.statuses
                .map(
                  (status) =>
                    statusOptions.find((o) => o.value === status)?.label
                )
                .join(", ")}
              onClear={() => setValue("statuses", [])}
            />
          )}
        </div>
      )}

      <Heading title="Creator Brands" count={creatorBrands.length} />

      {creatorBrands.length > 0 && (
        <CreatorBrandsTable creatorBrands={creatorBrands} />
      )}

      <InfiniteLoadMore
        onEndReached={() => {
          if (pageInfo?.endCursor) {
            fetchMore({
              variables: { creatorBrandsCursor: pageInfo.endCursor },
            })
          }
        }}
        canLoadMore={!loading && (pageInfo?.hasNextPage ?? false)}
        loadingText="Loading more creator brands..."
        loading={loading && creatorBrands.length > 0}
      />

      {!loading && !error && creatorBrands.length === 0 && (
        <NoResults
          title="No Creator Brands Found"
          description={
            activeSearch
              ? "Clear your search results to view all creator brands"
              : "We couldn't find any creator brands"
          }
          onClearSearch={activeSearch ? clearSearch : undefined}
        />
      )}
    </TablePageLayout>
  )
}

export const CREATOR_BRANDS_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query SearchCreatorBrands(
    $filters: CreatorBrandFilter
    $creatorBrandsCursor: String
  ) {
    creatorBrands(filters: $filters, after: $creatorBrandsCursor, first: 20) {
      edges {
        node {
          id
          ...CreatorBrandsTableRow
        }
      }

      pageInfo {
        ...Pagination
      }
    }
  }
`)
