import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { Params } from "static-path"
import { gql } from "~/__generated__"
import { Role, UserDetails_UserFragment } from "~/__generated__/graphql"
import { formatList } from "~/common/format-list"
import { gqlMatchOptional } from "~/common/gql-match"
import * as paths from "~/common/paths"
import { useDocumentTitle } from "~/common/use-document-title"
import { useGoBack } from "~/common/use-go-back"
import { GraphqlError } from "~/ui/errors"
import { ExternalLink } from "~/ui/external-link"
import { ButtonLink } from "~/ui/link"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Pane, PaneBody } from "~/ui/pane"
import { CompanyUserRole } from "./company-user-role"
import { UserAvatar } from "./user-avatar"

const query = gql(/* GraphQL */ `
  query UserDetailPaneQuery($userId: ID!) {
    node(id: $userId) {
      __typename
      ... on User {
        id
        ...UserDetails_user
      }
    }
  }
`)

type ParamsType =
  | Params<typeof paths.usersDetailPath.pattern>
  | Params<typeof paths.campaignDetailUserPath.pattern>
  | Params<typeof paths.campaignDeliverableUserPath.pattern>
  | Params<typeof paths.companyProfileUserPath.pattern>
  | Params<typeof paths.companyUsersDetailPath.pattern>

export const UserDetailPane: React.FC = () => {
  const params = useParams() as ParamsType
  const result = useQuery(query, { variables: { userId: params.userId } })
  const user = gqlMatchOptional(result.data?.node, "User")
  useDocumentTitle(user ? user.fullName : null)

  const backLink =
    "slug" in params
      ? paths.companyUsersPath(params)
      : "deliverableId" in params
      ? paths.campaignDeliverablePath(params)
      : "campaignId" in params
      ? paths.campaignDetailPath(params)
      : paths.usersPath({})

  const goBack = useGoBack(backLink)

  return (
    <Pane>
      <PaneBody className="pt-4 flex flex-col">
        <ButtonLink onClick={goBack} variant="close-pane" className="mb-[22px]">
          Close
        </ButtonLink>
        {result.loading ? (
          <LoadingIndicatorCentered />
        ) : result.error ? (
          <GraphqlError error={result.error} />
        ) : user ? (
          <UserDetails user={user} />
        ) : null}
      </PaneBody>
    </Pane>
  )
}

gql(/* GraphQL */ `
  fragment UserDetails_user on User {
    id
    fullName
    title
    email
    briefSummaryOfExperience
    favoriteAdOfAllTime
    calendlyUrl
    role

    companies(first: 100) {
      edges {
        role
        node {
          id
          name
        }
      }
    }
    ...UserAvatar
  }
`)

const UserDetails: React.FC<{ user: UserDetails_UserFragment }> = ({
  user,
}) => {
  const isMultipleCompanies = user.companies.edges.length > 1
  return (
    <div>
      <UserAvatar user={user} size="76" className="mb-2" />
      <div className="mb-6">
        <div className="text-lg font-medium">{user.fullName}</div>
        {user.title ? (
          <div className="text-xs-plus font-medium">{user.title}</div>
        ) : null}
      </div>

      <div className="grid gap-4">
        <UserDetailLine
          label="Email address"
          value={
            user.email ? (
              <a href={`mailto:${user.email}`} className="break-all">
                {user.email}
              </a>
            ) : null
          }
        />
        <UserDetailLine
          label={user.companies.edges.length === 1 ? "Company" : "Companies"}
          value={formatList(user.companies.edges.map((e) => e.node.name))}
        />

        {user.role === Role.Client && (
          <UserDetailLine
            label="Company Roles"
            value={
              <>
                {user.companies.edges.map((e, index) => (
                  <div key={e.node.id}>
                    <CompanyUserRole {...e} />
                    {isMultipleCompanies ? ` at ${e.node.name}` : ""}
                  </div>
                ))}
              </>
            }
          />
        )}

        {user.role === Role.WorkweekTeam || user.role === Role.WorkweekAdmin ? (
          <>
            <UserDetailLine
              label="Favorite Ad of All Time"
              value={user.favoriteAdOfAllTime}
            />
            <UserDetailLine
              label="Experience"
              value={user.briefSummaryOfExperience}
            />
            <UserDetailLine
              label="Calendly"
              value={
                user.calendlyUrl ? (
                  <ExternalLink href={user.calendlyUrl}>
                    View Calendar
                  </ExternalLink>
                ) : null
              }
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

const UserDetailLine: React.FC<{
  label: string
  value: React.ReactNode
}> = ({ label, value }) => {
  if (!value) return null
  return (
    <div className="grid">
      <div className="text-xs text-gray-99 mb-2">{label}</div>
      <div className="text-xs-plus font-medium whitespace-pre-wrap">
        {value}
      </div>
    </div>
  )
}
