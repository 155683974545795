import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { metricsFormSchema } from "~/campaigns/campaign-metrics-screen"
import { formatPercentage } from "~/common/percentage-formattingt"
import { calculatePercent } from "~/ui/percent"
import { CampaignTotals } from "../campaign-totals"

const formatDuration = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`
}

const timeToSeconds = (time: string): number => {
  const [minutes, seconds] = time.split(":").map(Number)
  return minutes * 60 + seconds
}

export const CampaignBrandedArticlesTotals: React.FC<{
  form: UseFormReturn<z.infer<typeof metricsFormSchema>>
}> = ({ form }) => {
  const { watch } = form
  const campaignDeliverables = watch("deliverables")
  const averageTimeOnSite = campaignDeliverables.reduce(
    (sum, deliverable, _, array) => {
      const seconds = timeToSeconds(deliverable.timeOnSiteMetric)
      return sum + seconds / array.length
    },
    0
  )
  const totalPageviews = campaignDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.pageviewsMetric || "0"),
    0
  )
  const totalClicks = campaignDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.totalClicksMetric || "0"),
    0
  )
  const averageClickThroughRate = calculatePercent(totalClicks, totalPageviews)

  const formattedAverageTimeOnSite = formatDuration(
    Math.round(averageTimeOnSite)
  )

  return (
    <CampaignTotals
      totals={[
        {
          value: formattedAverageTimeOnSite,
          label: "Average Time on Site",
        },
        {
          value: totalPageviews,
          label: "Total Pageviews",
        },
        {
          value: totalClicks,
          label: "Total Clicks",
        },
        {
          value: formatPercentage(averageClickThroughRate),
          label: "Average CTR",
        },
      ]}
    />
  )
}
