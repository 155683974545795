import { useLazyQuery, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { Editor } from "@tiptap/react"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useOutlet, useParams } from "react-router-dom"
import { Params } from "static-path"
import invariant from "tiny-invariant"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  AdPlacement,
  BylineFieldsFragmentFragment,
  SailthruResource,
} from "~/__generated__/graphql"
import { Section, SectionLabel } from "~/campaigns/section"
import { gqlMatchOptional } from "~/common/gql-match"
import * as paths from "~/common/paths"
import { useGoBack } from "~/common/use-go-back"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { SelectField } from "~/fields/select-field"
import { TextField } from "~/fields/text-field"
import arrowLeft from "~/images/icons/arrow-left"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Button } from "~/ui/button"
import { Form, FormField } from "~/ui/form"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"
import { BylineEditor } from "./byline-editor"
import { ToggleSection } from "./toggle-section"
import { StyledFormMessage } from "~/forms/styled-form-message"

const NEW_DRAFT_MUTATION = gql(/* GraphQL */ `
  mutation newDraft($input: CampaignDeliverableNewDraftInput!) {
    campaignDeliverableNewDraft(input: $input) {
      campaignDeliverable {
        id
        status
      }
    }
  }
`)

const SEND_TO_EXISTING_DRAFT_MUTATION = gql(/* GraphQL */ `
  mutation sendToExistingDraft(
    $input: CampaignDeliverableSendToExistingDraftInput!
  ) {
    campaignDeliverableSendToExistingDraft(input: $input) {
      campaignDeliverable {
        id
        status
      }
    }
  }
`)

const TEMPLATE_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query SailthruTemplate(
    $resourceId: ID!
    $campaignDeliverableId: ID!
    $resourceType: SailthruResource!
    $adPlacement: AdPlacement!
    $adLogoPrimary: String
    $adLogoPrimaryHtml: String
    $adContentPrimaryByline: String
    $adContentPrimaryBylineHtml: String
    $adContentSecondaryByline: String
    $adContentSecondaryBylineHtml: String
  ) {
    sailthruTemplate(
      resourceId: $resourceId
      campaignDeliverableId: $campaignDeliverableId
      resourceType: $resourceType
      adPlacement: $adPlacement
      adLogoPrimary: $adLogoPrimary
      adLogoPrimaryHtml: $adLogoPrimaryHtml
      adContentPrimaryByline: $adContentPrimaryByline
      adContentPrimaryBylineHtml: $adContentPrimaryBylineHtml
      adContentSecondaryByline: $adContentSecondaryByline
      adContentSecondaryBylineHtml: $adContentSecondaryBylineHtml
    ) {
      name
      contentHtml
      tagsMissing
    }
  }
`)

const CAMPAIGN_DELIVERABLE_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query CampaignDeliverableScaffoldQuery($campaignDeliverableId: ID!) {
    node(id: $campaignDeliverableId) {
      __typename
      ... on CampaignDeliverable {
        id
        placement
        ctaUrl

        creatorBrand {
          id
          name
          ctaColor
          bylineFields(first: 100) {
            __typename
            edges {
              node {
                ...BylineFieldsFragment
              }
            }
          }
        }

        campaign {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
`)

gql(/* GraphQL */ `
  fragment BylineFieldsFragment on BylineField {
    id
    content
    contentText
  }
`)

export const ScaffoldAdScreen = () => {
  const [createNewDraft, setCreateNewDraft] = useState(true)
  const [logoByLinePreset, setLogoByLinePreset] = useState(true)
  const [contentByLinePreset, setContentByLinePreset] = useState(true)
  const [templateId, setTemplateId] = useState<string | null>(null)
  const params =
    useParams<Params<typeof paths.campaignDeliverableScaffoldAdPath.pattern>>()

  invariant(params.campaignId, "Expected campaignId to be defined")
  invariant(params.deliverableId, "Expected deliverableId to be defined")

  const formSchema = z
    .object({
      title: z.string().optional(),
      templateName: z.string().optional(),
      blastId: z.string().optional(),
      adLogoBylineFieldId: z.string().optional(),
      adLogoPrimary: z.string().optional(),
      adLogoPrimaryHtml: z.string().optional(),
      adContentBylineFieldId: z.string().optional(),
      adContentPrimary: z.string().optional(),
      adContentPrimaryHtml: z.string().optional(),
      adContentSecondaryBylineFieldId: z.string().optional(),
      adContentSecondaryByline: z.string().optional(),
      adContentSecondaryBylineHtml: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (createNewDraft) {
        if (!data.title) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Title is required when creating a new draft",
            path: ["title"],
          })
        }
        if (!data.templateName) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Template is required when creating a new draft",
            path: ["templateName"],
          })
        }
      } else {
        if (!data.blastId) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Blast ID is required when sending to an existing draft",
            path: ["blastId"],
          })
        }
      }
    })

  const [
    fetchTemplate,
    {
      data: currentTemplateData,
      previousData: previousTemplateData,
      loading: templateLoading,
    },
  ] = useLazyQuery(TEMPLATE_QUERY_DOCUMENT)

  const templateData = currentTemplateData || previousTemplateData

  const goBack = useGoBack(
    paths.campaignDeliverablePath({
      campaignId: params.campaignId,
      deliverableId: params.deliverableId,
    })
  )
  const outlet = useOutlet()
  const { toast } = useToast()
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      templateName: "",
      blastId: "",
      adLogoBylineFieldId: "",
      adLogoPrimary: "",
      adLogoPrimaryHtml: "",
      adContentBylineFieldId: "",
      adContentPrimary: "",
      adContentPrimaryHtml: "",
      adContentSecondaryBylineFieldId: "",
      adContentSecondaryByline: "",
      adContentSecondaryBylineHtml: "",
    },
  })

  const [execSendToExistingDraft, sendToExistingDraftResult] = useSafeMutation(
    SEND_TO_EXISTING_DRAFT_MUTATION
  )
  useValidationErrors(form.setError, sendToExistingDraftResult)
  const [exec, result] = useSafeMutation(NEW_DRAFT_MUTATION)
  useValidationErrors(form.setError, result)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (createNewDraft) {
      const result = await exec({
        variables: {
          input: {
            id: params.deliverableId ?? "",
            name: values.title ?? "",
            templateName: values.templateName ?? "",
            adLogoPrimary: values.adLogoPrimary ?? "",
            adLogoPrimaryHtml: values.adLogoPrimaryHtml ?? "",
            adContentPrimaryByline: values.adContentPrimary ?? "",
            adContentPrimaryBylineHtml: values.adContentPrimaryHtml ?? "",
            adContentSecondaryByline: values.adContentSecondaryByline ?? "",
            adContentSecondaryBylineHtml:
              values.adContentSecondaryBylineHtml ?? "",
          },
        },
      })

      if (!result.errors) {
        toast({
          title: "Campaign Deliverable Ad Scaffolded",
          description: "The campaign deliverable ad has been scaffolded",
        })
        navigate(
          paths.campaignDeliverablePath({
            campaignId: params.campaignId ?? "",
            deliverableId: params.deliverableId ?? "",
          })
        )
      }
    } else {
      const result = await execSendToExistingDraft({
        variables: {
          input: {
            id: params.deliverableId ?? "",
            blastId: values.blastId ?? "",
            adLogoPrimary: values.adLogoPrimary ?? "",
            adLogoPrimaryHtml: values.adLogoPrimaryHtml ?? "",
            adContentPrimaryByline: values.adContentPrimary ?? "",
            adContentPrimaryBylineHtml: values.adContentPrimaryHtml ?? "",
            adContentSecondaryByline: values.adContentSecondaryByline ?? "",
            adContentSecondaryBylineHtml:
              values.adContentSecondaryBylineHtml ?? "",
          },
        },
      })

      if (!result.errors) {
        toast({
          title: "Campaign Deliverable Ad Scaffolded",
          description: "The campaign deliverable ad has been scaffolded",
        })
        // navigate(
        //   paths.campaignDeliverablePath({
        //     campaignId: params.campaignId ?? "",
        //     deliverableId: params.deliverableId ?? "",
        //   })
        // )
      }
    }
  }

  const adLogoPrimary = form.watch("adLogoPrimary")
  const [debouncedAdLogoPrimary] = useDebounce(adLogoPrimary, 500)
  const adLogoPrimaryHtml = form.watch("adLogoPrimaryHtml")
  const [debouncedAdLogoPrimaryHtml] = useDebounce(adLogoPrimaryHtml, 500)
  const adContentPrimary = form.watch("adContentPrimary")
  const [debouncedAdContentPrimary] = useDebounce(adContentPrimary, 500)
  const adContentPrimaryHtml = form.watch("adContentPrimaryHtml")
  const [debouncedAdContentPrimaryHtml] = useDebounce(adContentPrimaryHtml, 500)
  const adContentSecondaryByline = form.watch("adContentSecondaryByline")
  const [debouncedAdContentSecondaryByline] = useDebounce(
    adContentSecondaryByline,
    500
  )
  const adContentSecondaryBylineHtml = form.watch(
    "adContentSecondaryBylineHtml"
  )
  const [debouncedAdContentSecondaryBylineHtml] = useDebounce(
    adContentSecondaryBylineHtml,
    500
  )

  const campaignDeliverableResult = useQuery(
    CAMPAIGN_DELIVERABLE_QUERY_DOCUMENT,
    {
      variables: {
        campaignDeliverableId: params.deliverableId,
      },
    }
  )

  const campaignDeliverable = gqlMatchOptional(
    campaignDeliverableResult.data?.node,
    "CampaignDeliverable"
  )

  const adPlacement =
    campaignDeliverable?.placement === "Intro"
      ? AdPlacement.Intro
      : campaignDeliverable?.placement === "Secondary"
      ? AdPlacement.Secondary
      : AdPlacement.Primary

  useEffect(() => {
    if (templateId && params.deliverableId) {
      fetchTemplate({
        variables: {
          resourceId: templateId,
          adPlacement: adPlacement,
          campaignDeliverableId: params.deliverableId,
          resourceType: createNewDraft
            ? SailthruResource.Template
            : SailthruResource.Blast,
          adLogoPrimary: logoByLinePreset ? debouncedAdLogoPrimary : undefined,
          adLogoPrimaryHtml: logoByLinePreset
            ? debouncedAdLogoPrimaryHtml
            : undefined,
          adContentPrimaryByline: contentByLinePreset
            ? debouncedAdContentPrimary
            : undefined,
          adContentPrimaryBylineHtml: contentByLinePreset
            ? debouncedAdContentPrimaryHtml
            : undefined,
          adContentSecondaryByline: contentByLinePreset
            ? debouncedAdContentSecondaryByline
            : undefined,
          adContentSecondaryBylineHtml: contentByLinePreset
            ? debouncedAdContentSecondaryBylineHtml
            : undefined,
        },
      })
    }
  }, [
    templateId,
    fetchTemplate,
    params.deliverableId,
    createNewDraft,
    debouncedAdLogoPrimary,
    debouncedAdContentPrimary,
    debouncedAdContentPrimaryHtml,
    debouncedAdContentSecondaryByline,
    debouncedAdContentSecondaryBylineHtml,
    logoByLinePreset,
    contentByLinePreset,
    debouncedAdLogoPrimaryHtml,
    adPlacement,
  ])

  const {
    data: currentData,
    previousData,
    loading: sailthruDataLoading,
  } = useQuery(SAILTHRU_QUERY_DOCUMENT)

  const data = currentData || previousData
  let drafts = data?.sailthru?.drafts ?? []
  let templates = data?.sailthru?.templates ?? []

  const draftsOptions = drafts.map(
    (draft: { blastId: string; name: string }) => ({
      label: draft.name,
      value: draft.blastId,
    })
  )

  const templateOptions = templates.map(
    (template: { templateId: string; name: string }) => ({
      label: template.name,
      value: template.templateId,
    })
  )
  const creatorBrand = campaignDeliverable?.creatorBrand
  const bylineFields =
    creatorBrand?.bylineFields.edges.map(
      (edge: { node: BylineFieldsFragmentFragment }) => edge.node
    ) ?? []

  const bylineFieldsOptions = bylineFields.map(
    (field: BylineFieldsFragmentFragment) => ({
      label: field.contentText ?? "",
      value: field.id,
    })
  )

  const bylineLogoEditorRef = useRef<Editor | null>(null)
  const bylineContentEditorRef = useRef<Editor | null>(null)

  if (!campaignDeliverable) {
    return null
  }

  const placementPrimary = campaignDeliverable.placement === "Primary"
  const placementSecondary = campaignDeliverable.placement === "Secondary"

  const appendCompanyNameToByline = (byline: string | null | undefined) => {
    let updatedContent = ""

    if (byline) {
      try {
        const parsedContent = JSON.parse(byline)

        // Find the last paragraph
        const lastParagraph =
          parsedContent.content[parsedContent.content.length - 1]
        if (lastParagraph.type === "paragraph") {
          // Append a space to the last text node if it exists
          if (lastParagraph.content.length > 0) {
            const lastTextNode =
              lastParagraph.content[lastParagraph.content.length - 1]
            if (lastTextNode.type === "text") {
              lastTextNode.text += " "
            }
          }

          // Insert a new text node with a link mark
          lastParagraph.content.push({
            type: "text",
            text: campaignDeliverable.campaign.company.name,
            marks: [
              {
                type: "link",
                attrs: {
                  href: campaignDeliverable.ctaUrl,
                  target: "_blank",
                },
              },
            ],
          })
        }

        updatedContent = JSON.stringify(parsedContent)
      } catch (error) {
        throw new Error(`Error parsing or modifying byline content: ${error}`)
      }
    }

    return updatedContent
  }

  const tagsMissing = templateData?.sailthruTemplate?.tagsMissing

  return (
    <>
      <div className="px-[40px] border-b">
        <Button onClick={goBack} variant="ghost" className="-ms-4">
          <img {...arrowLeft} alt="" className="inline-block me-2" />
          <Text variant="mini-caps">Back</Text>
        </Button>
      </div>
      <div>
        <TablePageLayout padding={false} rightSideSlot={outlet}>
          <div className="flex flex-1 flex-col">
            <div className="grid grid-cols-[minmax(475px,1fr)_475px] p-[40px] gap-[40px] items-start overflow-auto">
              <div className="space-y-8">
                <Form {...form}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      form.handleSubmit(onSubmit)()
                    }}
                  >
                    <Section variant="bordered" className="space-y-4">
                      <div className="border-b boder-gray-d0 pb-8">
                        <SectionLabel variant="body-18-medium">
                          Scaffold Ad Instructions
                        </SectionLabel>
                        <Text variant="body-14">
                          How to prepare your Sailthru template with variable
                          tags
                        </Text>
                      </div>
                      <div className="pl-4">
                        {placementPrimary && <PlacementPrimaryInstructions />}
                        {placementSecondary && (
                          <PlacementSecondaryInstructions />
                        )}
                        {campaignDeliverable.placement === "Intro" && (
                          <PlacementIntroInstructions />
                        )}
                        {campaignDeliverable.placement === "DeepDive" && (
                          <PlacementDeepDiveInstructions />
                        )}
                      </div>
                      {tagsMissing &&
                        tagsMissing.length > 0 &&
                        createNewDraft && (
                          <StyledFormMessage>
                            Unable to find{" "}
                            <span className="font-bold">
                              {templateData?.sailthruTemplate?.tagsMissing
                                ?.map((t) => `{${t}}`)
                                .join(", ")}{" "}
                            </span>
                            in the email template you selected. Please check to
                            make sure that all required tags are included and
                            spelled correctly in your template.
                          </StyledFormMessage>
                        )}
                      {tagsMissing &&
                        tagsMissing.length > 0 &&
                        !createNewDraft && (
                          <StyledFormMessage>
                            Unable to find{" "}
                            <span className="font-bold">
                              {templateData?.sailthruTemplate?.tagsMissing
                                ?.map((t) => `{${t}}`)
                                .join(", ")}
                            </span>{" "}
                            in the email draft you selected. Please make sure
                            your draft has a template associated and that all
                            required tags are included and spelled correctly in
                            your template. If the issue persists, check to make
                            sure that there is not already another ad scaffolded
                            in the same placement.
                          </StyledFormMessage>
                        )}
                      <div className="flex items-center gap-8 pt-4">
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="createType"
                            value="newDraft"
                            checked={createNewDraft}
                            onChange={() => {
                              setTemplateId("")
                              setCreateNewDraft(true)
                            }}
                          />
                          <Text variant="body-14">Create new draft</Text>
                        </label>
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="scheduleType"
                            value="immediately"
                            checked={!createNewDraft}
                            onChange={() => {
                              setTemplateId("")
                              setCreateNewDraft(false)
                            }}
                          />

                          <Text variant="body-14">Send to existing draft</Text>
                        </label>
                      </div>
                      <div className="pl-4 space-y-4">
                        {createNewDraft && (
                          <>
                            <TextField
                              control={form.control}
                              name="title"
                              label="Title*"
                              required
                              placeholder="Email Title"
                            />

                            <FormField
                              control={form.control}
                              name="templateName"
                              render={(field) => (
                                <SelectField
                                  {...field}
                                  control={form.control}
                                  name="templateName"
                                  label="Template*"
                                  text={() =>
                                    field.field.value || "Select Template"
                                  }
                                  isLoading={sailthruDataLoading}
                                  options={templateOptions}
                                  onChange={(value) => {
                                    setTemplateId(value.toString())
                                    form.setValue(
                                      "templateName",
                                      templateOptions.find(
                                        (option: {
                                          value: string
                                          label: string
                                        }) => option.value === value
                                      )?.label || ""
                                    )
                                  }}
                                />
                              )}
                            />
                          </>
                        )}

                        {!createNewDraft && (
                          <FormField
                            control={form.control}
                            name="blastId"
                            render={(field) => (
                              <SelectField
                                {...field}
                                control={form.control}
                                name="blastId"
                                label="Draft*"
                                text={() =>
                                  draftsOptions.find(
                                    (option: {
                                      value: string
                                      label: string
                                    }) => option.value === field.field.value
                                  )?.label || "Select Draft"
                                }
                                isLoading={sailthruDataLoading}
                                options={draftsOptions}
                                onChange={(value) => {
                                  setTemplateId(value.toString())
                                  form.setValue(
                                    "blastId",
                                    draftsOptions.find(
                                      (option: {
                                        value: string
                                        label: string
                                      }) => option.value === value
                                    )?.value || ""
                                  )
                                }}
                              />
                            )}
                          />
                        )}
                        {placementPrimary && (
                          <>
                            <ToggleSection
                              label="Byline w/ logo"
                              name="logoByline"
                              value={logoByLinePreset}
                              onChange={(value) => {
                                setLogoByLinePreset(value)
                              }}
                            >
                              <BylineEditor
                                name="adLogoPrimary"
                                htmlName="adLogoPrimaryHtml"
                                selectName="adLogoBylineFieldId"
                                form={form}
                                bylineFieldsOptions={bylineFieldsOptions}
                                editorRef={bylineLogoEditorRef}
                                onSelectFieldChange={(value) => {
                                  const bylineField = bylineFields.find(
                                    (f) => f.id === value
                                  )
                                  form.setValue(
                                    "adLogoPrimary",
                                    bylineField?.content ?? ""
                                  )
                                  bylineLogoEditorRef.current?.commands.setContent(
                                    JSON.parse(bylineField?.content ?? "{}")
                                  )
                                  form.setValue(
                                    "adLogoPrimaryHtml",
                                    bylineLogoEditorRef.current?.getHTML()
                                  )
                                  form.setValue(
                                    "adLogoBylineFieldId",
                                    value.toString()
                                  )
                                }}
                              />
                            </ToggleSection>
                            <ToggleSection
                              label="Byline w/ content"
                              name="contentByline"
                              value={contentByLinePreset}
                              onChange={(value) => {
                                setContentByLinePreset(value)
                              }}
                            >
                              <BylineEditor
                                name="adContentPrimary"
                                htmlName="adContentPrimaryHtml"
                                selectName="adContentBylineFieldId"
                                form={form}
                                bylineFieldsOptions={bylineFieldsOptions}
                                editorRef={bylineContentEditorRef}
                                contentStyles={{
                                  color: creatorBrand?.ctaColor ?? "#000000",
                                }}
                                onSelectFieldChange={(value) => {
                                  const bylineField = bylineFields.find(
                                    (f) => f.id === value
                                  )
                                  let updatedContent =
                                    appendCompanyNameToByline(
                                      bylineField?.content
                                    )

                                  form.setValue(
                                    "adContentPrimary",
                                    updatedContent ?? ""
                                  )
                                  bylineContentEditorRef.current?.commands.setContent(
                                    JSON.parse(updatedContent ?? "")
                                  )
                                  form.setValue(
                                    "adContentPrimaryHtml",
                                    bylineContentEditorRef.current?.getHTML()
                                  )
                                  form.setValue(
                                    "adContentBylineFieldId",
                                    value.toString()
                                  )
                                }}
                              />
                            </ToggleSection>
                          </>
                        )}
                        {placementSecondary && (
                          <>
                            <ToggleSection
                              label="Byline w/ content"
                              name="adContentSecondaryByline"
                              value={contentByLinePreset}
                              onChange={(value) => {
                                setContentByLinePreset(value)
                              }}
                            >
                              <BylineEditor
                                name="adContentSecondaryByline"
                                htmlName="adContentSecondaryBylineHtml"
                                selectName="adContentSecondaryBylineFieldId"
                                contentStyles={{
                                  color: creatorBrand?.ctaColor ?? "#000000",
                                }}
                                form={form}
                                bylineFieldsOptions={bylineFieldsOptions}
                                editorRef={bylineContentEditorRef}
                                onSelectFieldChange={(value) => {
                                  const bylineField = bylineFields.find(
                                    (f) => f.id === value
                                  )
                                  let updatedContent =
                                    appendCompanyNameToByline(
                                      bylineField?.content
                                    )

                                  form.setValue(
                                    "adContentSecondaryByline",
                                    updatedContent ?? ""
                                  )
                                  bylineContentEditorRef.current?.commands.setContent(
                                    JSON.parse(updatedContent ?? "{}")
                                  )
                                  form.setValue(
                                    "adContentSecondaryBylineFieldId",
                                    value.toString()
                                  )
                                  form.setValue(
                                    "adContentSecondaryBylineHtml",
                                    bylineContentEditorRef.current?.getHTML()
                                  )
                                }}
                              />
                            </ToggleSection>
                          </>
                        )}
                        <div className="pt-4">
                          <Button
                            type="submit"
                            disabled={form.formState.isSubmitting}
                          >
                            Scaffold Ad
                          </Button>
                        </div>
                      </div>
                    </Section>
                    {(!createNewDraft || createNewDraft) && (
                      <Section variant="bordered" className="space-y-4">
                        <Text variant="body-18-medium">Ad Preview</Text>
                        {templateId ? (
                          templateLoading ? (
                            <LoadingIndicatorCentered />
                          ) : (
                            <HTMLInIframe
                              htmlContent={
                                templateData?.sailthruTemplate?.contentHtml
                              }
                            />
                          )
                        ) : (
                          <Text variant="body-14" className="text-gray-500">
                            {createNewDraft
                              ? "No template selected"
                              : "No blast selected"}
                          </Text>
                        )}
                      </Section>
                    )}
                  </form>
                </Form>
              </div>
            </div>
          </div>
        </TablePageLayout>
      </div>
    </>
  )
}

const SAILTHRU_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query Sailthru {
    sailthru {
      drafts {
        blastId
        name
      }

      templates {
        templateId
        name
      }

      timeZones
    }
  }
`)

const HTMLInIframe = ({ htmlContent }: { htmlContent: string | undefined }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument

      if (iframeDocument && htmlContent) {
        iframeDocument.open()
        iframeDocument.write(htmlContent)
        iframeDocument.close()
      }
    }
  }, [htmlContent])

  return (
    <iframe
      ref={iframeRef}
      title="HTML Content"
      className="h-[400px] w-full border-none"
    />
  )
}

const PlacementPrimaryInstructions = () => {
  return (
    <ul className="grid gap-4 max-w-lg list-disc">
      <li>
        <Text variant="body-14">
          Place the tag{" "}
          <span className="font-bold">
            {"{"}ad_logo_primary{"}"}
          </span>{" "}
          in the template where the logo should be placed.
        </Text>
      </li>
      <li>
        <Text variant="body-14">
          Place the tag{" "}
          <span className="font-bold">
            {"{"}ad_content_primary{"}"}
          </span>{" "}
          in the template where the ad content should be placed.
        </Text>
      </li>
    </ul>
  )
}

const PlacementSecondaryInstructions = () => {
  return (
    <ul className="grid gap-4 max-w-lg list-disc">
      <li>
        <Text variant="body-14">
          Place the tag{" "}
          <span className="font-bold">
            {"{"}ad_content_secondary{"}"}
          </span>{" "}
          in the template where the ad content should be placed.
        </Text>
      </li>
    </ul>
  )
}

const PlacementIntroInstructions = () => {
  return (
    <ul className="grid gap-4 max-w-lg list-disc">
      <li>
        <Text variant="body-14">
          Place the tag{" "}
          <span className="font-bold">
            {"{"}ad_content_intro{"}"}
          </span>{" "}
          in the template where the ad content should be placed.
        </Text>
      </li>
    </ul>
  )
}

const PlacementDeepDiveInstructions = () => {
  return (
    <ul className="grid gap-4 max-w-lg list-disc">
      <li>
        <Text variant="body-14">
          <span className="font-bold">Ad copy insertion:</span> Create a text
          block where you'd like the ad copy to be placed and copy/paste the{" "}
          {"{"}ad_copy_deepdive{"}"} tag into the text block.
        </Text>
      </li>
    </ul>
  )
}
