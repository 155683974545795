import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CompaniesTableRowFragment,
  CompanyStatus,
} from "~/__generated__/graphql"
import { companyProfileEditPath, companyProfilePath } from "~/common/paths"
import { pickNonDefaults } from "~/common/zod-changed-defaults"
import { URLParamsSerializer } from "~/common/zod-search-params"
import { FilterField } from "~/fields/filter-field"
import ArrowRightIcon from "~/images/icons/arrow-right.svg?react"
import { ActiveFilters } from "~/search/active-filters"
import { NoResults } from "~/search/no-results"
import { SearchInput } from "~/search/search-input"
import { Form, FormField } from "~/ui/form"
import { Heading } from "~/ui/heading"
import { InfiniteLoadMore } from "~/ui/infinite-load-more"
import { Link } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { CompanyLogo } from "./company-logo"

type CompaniesTableProps = {
  companies: Array<CompaniesTableRowFragment>
}

const formSchema = z.object({
  search: z.string(),
  statuses: z.array(z.nativeEnum(CompanyStatus)),
})

type FormValues = z.infer<typeof formSchema>

const defaultValues: FormValues = {
  search: "",
  statuses: [CompanyStatus.Active],
}

const urlSerializer = new URLParamsSerializer(formSchema, defaultValues)

const CompaniesTable = ({ companies }: CompaniesTableProps) => {
  const navigate = useNavigate()
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Company Name</TableHead>
          <TableHead>Main Contact(s)</TableHead>
          <TableHead>Actions</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {companies.map((company) => (
          <TableRow
            key={company.id}
            onClick={() => navigate(companyProfilePath({ slug: company.slug }))}
          >
            <TableCell>
              <div className="flex items-center gap-2">
                <CompanyLogo company={company} />
                {company.name}
              </div>
            </TableCell>
            <TableCell></TableCell>
            <TableCell className="w-24">
              Archive{" "}
              <Link
                to={companyProfileEditPath({ slug: company.slug })}
                variant="unstyled"
              >
                Edit
              </Link>
            </TableCell>
            <TableCell className="w-8">
              <LinkButton
                to={companyProfilePath({ slug: company.slug })}
                variant="ghost"
                size="icon"
              >
                <ArrowRightIcon />
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const CompaniesScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formSchema.parse({
      search: searchParams.get("search") ?? "",
      statuses:
        searchParams.getAll("status").length > 0
          ? (searchParams.getAll("status") as CompanyStatus[])
          : [CompanyStatus.Active],
    }),
  })

  const { watch, setValue } = form

  const formData = watch()

  const [debouncedSearch] = useDebounce(formData.search, 200)

  const { data, previousData, loading, error, fetchMore } = useQuery(
    COMPANIES_QUERY_DOCUMENT,
    {
      variables: {
        search: debouncedSearch,
        statuses: formData.statuses.length > 0 ? formData.statuses : undefined,
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  const clearSearch = () => {
    setValue("search", "")
  }

  const currentData = data || previousData
  const companies = currentData?.companies.edges.map((edge) => edge.node) ?? []
  const pageInfo = currentData?.companies.pageInfo

  const statusOptions = [
    { label: "Active", value: CompanyStatus.Active },
    { label: "Inactive", value: CompanyStatus.Inactive },
  ]

  useEffect(() => {
    const newSearchParams = urlSerializer.serialize(formData)
    if (newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [formData, searchParams, setSearchParams])

  const nonDefaultValues = pickNonDefaults(formSchema, defaultValues, formData)

  const activeFiltersCount = Object.keys(nonDefaultValues).filter(
    (key) =>
      key !== "search" &&
      key === "statuses" &&
      form.watch("statuses").length > 0
  ).length

  return (
    <div className="flex flex-1">
      <div className="px-4 py-8 flex-1 grow">
        <div className="space-y-4">
          <div className="px-4 space-y-8">
            <div className="flex items-center gap-2">
              <Form {...form}>
                <form className="flex items-center gap-2">
                  <FormField
                    control={form.control}
                    name="search"
                    render={({ field }) => (
                      <SearchInput placeholder="Search Companies" {...field} />
                    )}
                  />
                  <FilterField
                    control={form.control}
                    name="statuses"
                    options={statusOptions}
                    text="Status"
                  />
                </form>
              </Form>
            </div>

            {activeFiltersCount > 0 && (
              <div className="bg-gray-50 rounded-lg p-5 py-4 flex items-center gap-6">
                <Heading
                  title="Filters applied"
                  count={activeFiltersCount}
                  className="mb-0"
                />
                {formData.statuses.length > 0 && (
                  <ActiveFilters
                    label="Status"
                    values={formData.statuses
                      .map(
                        (status) =>
                          statusOptions.find(
                            (option) => option.value === status
                          )?.label ?? ""
                      )
                      .join(", ")}
                    onClear={() => setValue("statuses", [])}
                  />
                )}
              </div>
            )}

            {companies.length > 0 && <CompaniesTable companies={companies} />}

            <InfiniteLoadMore
              onEndReached={() => {
                if (pageInfo?.endCursor) {
                  fetchMore({
                    variables: { companiesCursor: pageInfo.endCursor },
                  })
                }
              }}
              canLoadMore={!loading && (pageInfo?.hasNextPage ?? false)}
              loadingText="Loading more companies..."
              loading={loading && companies.length > 0}
            />

            {!loading && !error && companies.length === 0 && (
              <NoResults
                title="No Companies Found"
                description="Clear your search results to view all companies"
                onClearSearch={clearSearch}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const COMPANIES_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query SearchCompanies(
    $search: String
    $companiesCursor: String
    $statuses: [CompanyStatus!]
  ) {
    companies(
      search: $search
      first: 20
      after: $companiesCursor
      statuses: $statuses
    ) {
      edges {
        node {
          id
          ...CompaniesTableRow
        }
      }

      pageInfo {
        ...Pagination
      }
    }
  }
`)

gql(/* GraphQL */ `
  fragment CompaniesTableRow on Company {
    id
    name
    slug
    ...CompanyLogo
  }
`)
