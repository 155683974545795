import { UseFormReturn } from "react-hook-form"
import { SelectField } from "~/fields/select-field"
import { MinimalTiptapEditor } from "~/tiptap"
import { FormField } from "~/ui/form"

export const BylineEditor = ({
  form,
  bylineFieldsOptions,
  onSelectFieldChange,
  editorRef,
  name,
  htmlName,
  selectName,
  contentStyles = {},
}: {
  form: UseFormReturn<any>
  bylineFieldsOptions: Array<{
    label: string
    value: string
  }>
  onSelectFieldChange?: (value: string) => void
  editorRef: React.RefObject<any>
  name: string
  htmlName?: string
  selectName: string
  contentStyles?: React.CSSProperties
}) => {
  const adContentPrimary = form.watch(name)

  return (
    <div className="mt-4 space-y-4">
      <FormField
        control={form.control}
        name={selectName}
        render={(field) => (
          <SelectField
            control={form.control}
            name={selectName}
            options={bylineFieldsOptions}
            text={() =>
              bylineFieldsOptions.find(
                (option: { value: string; label: string }) =>
                  option.value === field.field.value
              )?.label || "Select Byline Preset"
            }
            onChange={(value) => {
              onSelectFieldChange?.(value.toString())
            }}
          />
        )}
      />
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <>
            <MinimalTiptapEditor
              contentStyle={contentStyles}
              editorRef={editorRef}
              value={
                adContentPrimary && adContentPrimary.length > 0
                  ? JSON.parse(adContentPrimary)
                  : null
              }
              onValueChange={(value, html) => {
                form.setValue(name, JSON.stringify(value))
                if (htmlName) {
                  form.setValue(htmlName, html)
                }
              }}
              config={{
                bold: true,
                italic: true,
                underline: true,
                link: true,
                orderedList: false,
                bulletList: false,
                textSize: true,
              }}
            />
          </>
        )}
      />
    </div>
  )
}
