import { useQuery } from "@apollo/client"
import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { gql } from "~/__generated__"
import { CampaignDeliverableDetailFragmentFragment } from "~/__generated__/graphql"
import { formSchema } from "~/campaigns/campaign-deliverable-briefs"
import { Section, SectionLabel } from "~/campaigns/section"
import {
  companyProductsDetailPath,
  companyProductSnapshotPath,
  newCompanyProductsPath,
} from "~/common/paths"
import { SelectField } from "~/fields/select-field"
import { FormField } from "~/ui/form"
import { LinkButton } from "~/ui/link-button"

type ProductBriefProps = {
  form: UseFormReturn<z.infer<typeof formSchema>>
  campaignDeliverable: CampaignDeliverableDetailFragmentFragment
}

export const ProductBrief = ({
  form,
  campaignDeliverable,
}: ProductBriefProps) => {
  const {
    data: currentProductBriefsData,
    previousData: previousProductBriefsData,
    loading: productBriefsLoading,
  } = useQuery(PRODUCTS_QUERY_DOCUMENT, {
    variables: {
      companyId: campaignDeliverable.campaign.company.id,
    },
  })

  const productBriefsData =
    currentProductBriefsData || previousProductBriefsData
  let productBriefs =
    productBriefsData?.productBriefs.edges.map((edge) => edge.node) ?? []

  const productBriefNameFromId = (id: string) => {
    const productBrief = productBriefs.find(
      (productBrief) => productBrief?.id === id
    )
    return productBrief?.name
  }
  const companySlugFromProductBriefId = (id: string) => {
    const productBrief = productBriefs.find(
      (productBrief) => productBrief?.id === id
    )
    return productBrief?.company.slug
  }

  const productBriefId = form.watch("productBriefId")

  return (
    <Section className="m b-6 flex-col space-y-1" variant="bordered">
      <SectionLabel variant="body-18-medium">Product Brief</SectionLabel>
      <div>
        {form.formState.errors.trackingUrls && (
          <div className="text-sm font-medium text-destructive">
            {form.formState.errors.trackingUrls.message}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-end gap-2">
          <div className="max-w-xl flex-1">
            <FormField
              control={form.control}
              name="productBriefId"
              render={({ field }) => (
                <SelectField
                  {...field}
                  control={form.control}
                  name="productBriefId"
                  disabled={
                    !campaignDeliverable.canUpdateBrief.value ||
                    productBriefsLoading
                  }
                  label="Product Brief*"
                  text={({ value }) => <>{productBriefNameFromId(value)}</>}
                  options={productBriefs.map((productBrief) => ({
                    label: productBrief.name,
                    value: productBrief.id,
                  }))}
                />
              )}
            />
          </div>

          {productBriefId && (
            <LinkButton
              target="_blank"
              variant="cardControl"
              to={
                campaignDeliverable.productBriefSnapshot
                  ? companyProductSnapshotPath({
                      slug: companySlugFromProductBriefId(productBriefId) ?? "",
                      productId: productBriefId,
                      snapshotId: campaignDeliverable.productBriefSnapshot.id,
                    })
                  : companyProductsDetailPath({
                      slug: companySlugFromProductBriefId(productBriefId) ?? "",
                      productId: productBriefId,
                    })
              }
            >
              View Product Brief
            </LinkButton>
          )}
        </div>
        <div>
          <LinkButton
            target="_blank"
            size="xs"
            variant="linkMuted"
            to={newCompanyProductsPath({
              slug: campaignDeliverable.campaign.company.slug,
            })}
          >
            Open Create New Product page
          </LinkButton>
        </div>
      </div>
    </Section>
  )
}

const PRODUCTS_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query SearchProductBriefs($search: String, $companyId: ID, $first: Int = 10) {
    productBriefs(
      filter: { search: $search, companyId: $companyId }
      first: $first
    ) {
      edges {
        node {
          id
          name
          company {
            id
            slug
          }
        }
      }
    }
  }
`)
