import { Anchor } from "~/ui/link"
import { TOS_URL, PRIVACY_POLICY_URL } from "./sign-in-screen"

export function AccessLegalLinks() {
  return (
    <div className="text-xs text-center">
      By clicking “Get Magic Link” you agree with our{" "}
      <Anchor href={TOS_URL} className="whitespace-nowrap">
        Terms of Service
      </Anchor>{" "}
      and{" "}
      <Anchor href={PRIVACY_POLICY_URL} className="whitespace-nowrap">
        Privacy Policy
      </Anchor>
      .
    </div>
  )
}
