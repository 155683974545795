import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { metricsFormSchema } from "~/campaigns/campaign-metrics-screen"
import { formatPercentage } from "~/common/percentage-formattingt"
import { calculatePercent } from "~/ui/percent"
import { CampaignTotals } from "../campaign-totals"

export const CampaignSocialTotals: React.FC<{
  form: UseFormReturn<z.infer<typeof metricsFormSchema>>
}> = ({ form }) => {
  const { watch } = form
  const campaignDeliverables = watch("deliverables")
  const totalImpressions = campaignDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.impressionsMetric || "0"),
    0
  )
  const totalClicks = campaignDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.totalClicksMetric || "0"),
    0
  )
  const averageClickThroughRate = calculatePercent(
    totalClicks,
    totalImpressions
  )

  return (
    <CampaignTotals
      totals={[
        {
          value: totalImpressions,
          label: "Total Impressions",
        },
        {
          value: totalClicks,
          label: "Total Clicks",
        },
        {
          value: formatPercentage(averageClickThroughRate),
          label: "Average CTR",
        },
      ]}
    />
  )
}
