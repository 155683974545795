import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { Params } from "static-path"
import { z } from "zod"
import { gql } from "~/__generated__"
import { gqlMatchOptional } from "~/common/gql-match"
import {
  campaignDeliverableEditMetricsPath,
  campaignDeliverablePath,
} from "~/common/paths"
import { TextField } from "~/fields/text-field"
import { TextareaField } from "~/fields/textarea-field"
import { Button } from "~/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Input } from "~/ui/input"
import { Link } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import { Pane, PaneBody } from "~/ui/pane"
import Text from "~/ui/typography"

const query = gql(/* GraphQL */ `
  query CampaignDeliverableEditMetricsPaneQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on CampaignDeliverable {
        id
        canEditMetrics {
          value
        }
      }
    }
  }
`)

type ClickType = "clicks" | "realClicks" | "manualClicks"

export const CampaignDeliverableEditMetricsPane = () => {
  const [clickType, setClickType] = useState<ClickType>("clicks")
  // const [clickThroughRate, setClickThroughRate] = useState<number>(0)
  const params = useParams() as Params<
    typeof campaignDeliverableEditMetricsPath.pattern
  >

  const metrics = {
    emailRecipients: 37619,
    opens: 20506,
    clicks: 329,
    realClicks: 159,
    notes:
      "Consequat qui nostrud reprehenderit qui. Nostrud eu occaecat ipsum minim duis minim qui officia culpa. Occaecat officia Lorem aliquip sunt consectetur ipsum deserunt elit laborum consequat. Reprehenderit exercitation consectetur ut magna exercitation occaecat excepteur laborum. Sit ipsum aliquip in eiusmod sit.",
  }

  const formSchema = z.object({
    emailRecipients: z.number(),
    opens: z.number(),
    clicks: z.number(),
    manualClicks: z.number(),
    notes: z.string(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emailRecipients: metrics.emailRecipients,
      opens: metrics.opens,
      clicks: metrics.clicks,
      manualClicks: 0,
      notes: metrics.notes,
    },
  })

  const result = useQuery(query, {
    variables: {
      id: params.deliverableId,
    },
  })

  const deliverable = gqlMatchOptional(result.data?.node, "CampaignDeliverable")

  if (!deliverable?.canEditMetrics?.value) {
    return (
      <Pane>
        <PaneBody className="pt-4 flex flex-col border-b">
          <h2 className="font-medium text-lg">Edit Metrics</h2>
        </PaneBody>
        <div className="text-center text-sm text-danger py-4">
          You do not have permission to update this deliverable's metrics.
        </div>
      </Pane>
    )
  }

  const onSubmit = async (values: z.infer<typeof formSchema>) => {}

  const clicks = form.watch("clicks")
  const emailRecipients = form.watch("emailRecipients")
  const opens = form.watch("opens")

  const openRate = (opens / emailRecipients) * 100
  const clickThroughRate = (clicks / emailRecipients) * 100

  return (
    <Pane>
      <PaneBody className="pt-4 flex flex-col border-b">
        <Link
          to={campaignDeliverablePath({
            campaignId: params.campaignId,
            deliverableId: params.deliverableId,
          })}
          variant="close-pane"
          className="mb-2"
        >
          Close
        </Link>
        <h2 className="font-medium text-lg">Edit Metrics</h2>
      </PaneBody>
      <PaneBody className="pt-4 flex flex-col">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <TextField
                control={form.control}
                name="emailRecipients"
                label="Email Recipients"
                onChange={(e) => {
                  form.setValue("emailRecipients", e.target.valueAsNumber)
                }}
                required
              />
              <TextField
                control={form.control}
                name="opens"
                label="Opens"
                onChange={(e) => {
                  form.setValue("opens", e.target.valueAsNumber)
                }}
                required
              />
              <div className="space-y-2">
                <Text variant="body-14">
                  Open Rate <span className="text-gray-400">(calculated)</span>
                </Text>
                <Input disabled value={`${openRate.toFixed(2)}%`} />
              </div>
              <div className="grid gap-2">
                <FormLabel>Clicks</FormLabel>

                <div className="grid gap-1">
                  <div className="flex items-center gap-2">
                    <input
                      checked={clickType === "clicks"}
                      type="radio"
                      name="clickType"
                      value="clicks"
                      onChange={() => {
                        setClickType("clicks")
                        form.setValue("clicks", metrics.clicks)
                      }}
                    />
                    <Text variant="body-14-medium">{metrics.clicks}</Text>{" "}
                    <Text variant="body-14">Total Clicks</Text>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      checked={clickType === "realClicks"}
                      type="radio"
                      name="clickType"
                      value="realClicks"
                      onChange={() => {
                        setClickType("realClicks")
                        form.setValue("clicks", metrics.realClicks)
                      }}
                    />
                    <Text variant="body-14-medium">{metrics.realClicks}</Text>{" "}
                    <Text variant="body-14">Total Real Clicks</Text>
                  </div>

                  <div className="flex gap-2">
                    <input
                      checked={clickType === "manualClicks"}
                      type="radio"
                      name="clickType"
                      value="manualClicks"
                      onChange={() => setClickType("manualClicks")}
                      className="mt-1"
                    />
                    <div>
                      <FormField
                        control={form.control}
                        name="manualClicks"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Manual</FormLabel>
                            {clickType === "manualClicks" && (
                              <FormControl>
                                <Input
                                  {...field}
                                  type="number"
                                  value={form.watch("manualClicks")}
                                  onChange={(e) => {
                                    form.setValue(
                                      "manualClicks",
                                      e.target.valueAsNumber
                                    )
                                    form.setValue(
                                      "clicks",
                                      e.target.valueAsNumber
                                    )
                                  }}
                                />
                              </FormControl>
                            )}
                            <FormMessage className="text-xs" />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                <Text variant="body-14">
                  Click Through Rate{" "}
                  <span className="text-gray-400">(calculated)</span>
                </Text>
                <Input disabled value={`${clickThroughRate.toFixed(2)}%`} />
              </div>
              <TextareaField
                control={form.control}
                name="notes"
                label="Notes"
                rows={10}
                required
              />
            </div>

            <div className="flex justify-between items-center mt-6">
              <Button
                type="submit"
                disabled={form.formState.isSubmitting}
                className="font-light"
              >
                Save
              </Button>
              <LinkButton
                variant="linkMuted"
                type="button"
                to={campaignDeliverablePath({
                  campaignId: params.campaignId,
                  deliverableId: params.deliverableId,
                })}
                className="text-xs-plus text-gray-400 pr-0"
              >
                Cancel
              </LinkButton>
            </div>
          </form>
        </Form>
      </PaneBody>
    </Pane>
  )
}
