import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Affiliation } from "~/__generated__/graphql"
import * as paths from "~/common/paths"
import { useViewerMaybe } from "../auth/viewer-context"
import { LinkButton } from "../ui/link-button"

export const RootScreen = () => {
  const { viewer } = useViewerMaybe()
  const navigate = useNavigate()

  useEffect(() => {
    if (viewer) {
      // arbitrary choice of first company when someone lands on root path
      const defaultCompany = viewer.companies.edges[0]
      if (!defaultCompany) {
        navigate(paths.noCompaniesPath({}))
        return
      }

      if (viewer.affiliation === Affiliation.WorkweekInternal) {
        navigate(paths.dashboardPath({}))
      } else {
        navigate(paths.companyDashboardPath({ slug: defaultCompany.node.slug }))
      }
    }
  }, [navigate, viewer])

  return (
    <div className="p-4">
      <LinkButton to={paths.signInPath({})}>Sign In</LinkButton>
    </div>
  )
}
