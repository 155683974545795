import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { gql } from "~/__generated__"
import { CampaignDeliverableStatus } from "~/__generated__/graphql"
import { toCalendarDate } from "~/common/date-formatting"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { SelectField } from "~/fields/select-field"
import { TextField } from "~/fields/text-field"
import { Button } from "~/ui/button"
import { DatePicker } from "~/ui/date-picker"
import { Dialog, DialogContent } from "~/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { toast } from "~/ui/use-toast"

const CAMPAIGN_DELIVERABLE_UPDATE_STATUS_MUTATION = gql(/* GraphQL */ `
  mutation campaignDeliveryUpdateStatus(
    $input: CampaignDeliverableUpdateStatusInput!
  ) {
    campaignDeliveryUpdateStatus(input: $input) {
      campaignDeliverable {
        id
        status
        scheduledForFormatted
        publishDateCanonical
      }
    }
  }
`)

export const ChangeStatusModal = ({
  isOpen,
  onClose,
  campaignDeliverable,
}: {
  isOpen: boolean
  onClose: () => void
  campaignDeliverable: any
}) => {
  const formSchema = z
    .object({
      status: z.nativeEnum(CampaignDeliverableStatus),
      publishDate: z.date().optional(),
      publishTime: z.string().optional(),
      publishTimezone: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (
        data.status !== CampaignDeliverableStatus.AdScaffolded &&
        !data.publishDate
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Date cannot be empty",
          path: ["publishDate"],
        })
        return false
      }
      return true
    })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      status: campaignDeliverable.status,
      publishDate: campaignDeliverable.publushDateCanonical
        ? toCalendarDate(new Date(campaignDeliverable.publishDateCanonical))
        : undefined,
      publishTime:
        campaignDeliverable.publishTime ??
        new Date().toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        }),
      publishTimezone: campaignDeliverable.publishTimezone ?? "UTC",
    },
  })

  const [exec, result] = useSafeMutation(
    CAMPAIGN_DELIVERABLE_UPDATE_STATUS_MUTATION
  )
  useValidationErrors(form.setError, result)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await exec({
      variables: {
        input: {
          id: campaignDeliverable.id,
          status: values.status,
          ...(values.status !== CampaignDeliverableStatus.AdScaffolded && {
            publishDate: values.publishDate
              ? toCalendarDate(values.publishDate).toISOString()
              : new Date().toISOString(),
            publishTime: values.publishTime,
            publishTimezone: values.publishTimezone,
          }),
        },
      },
    })

    if (!result.errors) {
      toast({
        title: "Deliverable Status updated",
        description: "The campaign deliverable status has been updated",
      })

      onClose()
    }
  }

  const statusOptions = [
    {
      label: "Ad Scaffolded",
      value: CampaignDeliverableStatus.AdScaffolded,
    },
    {
      label: "Scheduled",
      value: CampaignDeliverableStatus.Scheduled,
    },
    {
      label: "Published",
      value: CampaignDeliverableStatus.Published,
    },
  ]

  const status = form.watch("status")
  const timezoneOptions = [
    { value: "UTC", label: "UTC" },
    {
      value: "America/New_York",
      label: "Eastern Time (ET)",
    },
    {
      value: "America/Chicago",
      label: "Central Time (CT)",
    },
    {
      value: "America/Denver",
      label: "Mountain Time (MT)",
    },
    {
      value: "America/Los_Angeles",
      label: "Pacific Time (PT)",
    },
    // Add more timezone options as needed
  ]

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) onClose()
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="text-center">
          <div className="text-2xl mb-4">
            Change Campaign Deliverable Status
          </div>
          <div className="mb-6 text-sm">
            Change the status of this deliverable
          </div>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col space-y-4"
          >
            <div className="grid gap-4">
              <FormField
                control={form.control}
                name="status"
                render={({ field }) => (
                  <SelectField
                    {...field}
                    control={form.control}
                    name="status"
                    label="Status*"
                    text={({ value }) => (
                      <>
                        {statusOptions.find((o) => o.value === value)?.label ??
                          "Select a status"}
                      </>
                    )}
                    options={statusOptions}
                  />
                )}
              />
            </div>
            {[
              CampaignDeliverableStatus.Published,
              CampaignDeliverableStatus.Scheduled,
            ].includes(status) && (
              <div className="flex items-center space-x-4">
                <FormField
                  control={form.control}
                  name="publishDate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="block">Date*</FormLabel>
                      <FormControl>
                        <DatePicker date={field.value} {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="publishTime"
                  render={({ field }) => (
                    <TextField
                      control={form.control}
                      {...field}
                      type="time"
                      label="Time"
                    />
                  )}
                />
                <FormField
                  control={form.control}
                  name="publishTimezone"
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      control={form.control}
                      text={({ value }) => (
                        <>
                          {
                            timezoneOptions.find((o) => o.value === value)
                              ?.label
                          }
                        </>
                      )}
                      options={timezoneOptions}
                      name="publishTimezone"
                      label="Timezone"
                    />
                  )}
                />
              </div>
            )}

            <Button type="submit" disabled={result.loading}>
              Save
            </Button>
          </form>
        </Form>

        <div className="flex mt-4">
          <Button
            type="button"
            onClick={() => onClose()}
            variant="linkMuted"
            size="xs"
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
