import { CampaignDeliverableStatus as StatusEnum } from "~/__generated__/graphql"

const campaignDeliverableStatusLabels: Record<StatusEnum, string> = {
  [StatusEnum.Approved]: "Approved",
  [StatusEnum.AssignedToCreative]: "Assigned to Creative",
  [StatusEnum.AwaitingAccountManagerApproval]:
    "Awaiting Account Manager Approval",
  [StatusEnum.AwaitingClientApproval]: "Awaiting Client Approval",
  [StatusEnum.AwaitingCreatorApproval]: "Awaiting Creator Approval",
  [StatusEnum.BriefNeeded]: "Brief Needed",
  [StatusEnum.Published]: "Published",
  [StatusEnum.Scheduled]: "Scheduled",
  [StatusEnum.AdScaffolded]: "Ad Scaffolded",
}

export const CampaignDeliverableStatusLabel: React.FC<{
  status: StatusEnum
}> = (props) => {
  return campaignDeliverableStatusLabels[props.status]
}
