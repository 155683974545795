import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { CampaignMetricsDeliverableFragment } from "~/__generated__/graphql"
import { formatDate } from "~/common/date-formatting"
import * as paths from "~/common/paths"
import arrowDown from "~/images/icons/arrow-down"
import arrowUp from "~/images/icons/arrow-up"
import { Input } from "~/ui/input"
import { Anchor } from "~/ui/link"
import { TableCell, TableRow } from "~/ui/table"
import { Textarea } from "~/ui/textarea"
import Text from "~/ui/typography"

export const PodcastDeliverableRow = ({
  deliverable,
  isEditing,
  index,
}: {
  deliverable: CampaignMetricsDeliverableFragment
  isEditing: boolean
  index: number
}) => {
  const { register, watch } = useFormContext()
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => (isEditing ? null : setIsOpen((prev) => !prev))

  const tableProps = { variant: isOpen ? "top" : "default" } as const

  const renderEditableField = (fieldName: string) => {
    const value = watch(`deliverables.${index}.${fieldName}`)

    return isEditing ? (
      <div>
        <Input
          type="number"
          {...register(`deliverables.${index}.${fieldName}` as const)}
          className="w-[80px]"
        />
      </div>
    ) : (
      <span>{value || "0"}</span>
    )
  }

  const renderEditableNotesField = () => {
    const value = watch(`deliverables.${index}.metricNotes`)
    return isEditing ? (
      <div>
        <Textarea
          {...register(`deliverables.${index}.metricNotes` as const)}
          className="w-full"
          value={value}
        />
      </div>
    ) : (
      <>
        {value && <span>{value}</span>}
        {!deliverable.metricNotes && (
          <Text variant="body-12" className="text-gray-500">
            No notes for this deliverable
          </Text>
        )}
      </>
    )
  }
  return (
    <>
      <TableRow
        onClick={toggleOpen}
        aria-label="View Campaign Deliverable notes"
      >
        <TableCell
          {...tableProps}
          className="p-0 first:pl-4"
          onClick={toggleOpen}
        >
          {isOpen && <img {...arrowUp} alt="" className="w-3 h-3" />}
          {!isOpen && <img {...arrowDown} alt="" className="w-3 h-3" />}
        </TableCell>
        <TableCell {...tableProps} className="py-4">
          {deliverable.publishDateCanonical
            ? formatDate(deliverable.publishDateCanonical, "MMMM d, yyyy")
            : "–"}
        </TableCell>
        <TableCell {...tableProps} className="py-4">
          <Text variant="body-14-medium">
            <Anchor
              target="_blank"
              className="no-underline hover:underline"
              href={paths.campaignDeliverablePath({
                campaignId: deliverable.campaign.id,
                deliverableId: deliverable.id,
              })}
            >
              {deliverable.deliverableName}
            </Anchor>
          </Text>
        </TableCell>
        <TableCell {...tableProps} className="py-4">
          <Text variant="body-14-medium">
            <Anchor
              target="_blank"
              className="no-underline hover:underline"
              href={paths.companyProductsDetailPath({
                slug: deliverable.productBrief?.company?.slug ?? "",
                productId: deliverable.productBrief?.id ?? "",
              })}
            >
              {deliverable.productBrief?.name}
            </Anchor>
          </Text>
        </TableCell>
        <TableCell {...tableProps} className="py-4">
          {" "}
          <Text variant="body-14-medium">
            <Anchor
              target="_blank"
              className="no-underline hover:underline"
              href={paths.creatorBrandsEditPath({
                creatorBrandId: deliverable.creatorBrand?.id ?? "",
              })}
            >
              {deliverable.creatorBrand?.name}
            </Anchor>
          </Text>
        </TableCell>
        <TableCell
          {...tableProps}
          className="py-4 !overflow-x-visible !overflow-y-visible"
        >
          {renderEditableField("downloads7DaysPostLaunchMetric")}
        </TableCell>
        <TableCell
          {...tableProps}
          className="py-4 !overflow-x-visible !overflow-y-visible"
        >
          {renderEditableField("downloads30DaysPostLaunchMetric")}
        </TableCell>
        <TableCell
          {...tableProps}
          className="py-4 !overflow-x-visible !overflow-y-visible"
        >
          {renderEditableField("impressions7DaysPostLaunchMetric")}
        </TableCell>
        <TableCell {...tableProps} className="py-4">
          {renderEditableField("impressions30DaysPostLaunchMetric")}
        </TableCell>
        <TableCell {...tableProps} className="py-4">
          {renderEditableField("impressions")}
        </TableCell>
      </TableRow>
      {(isOpen || isEditing) && (
        <TableRow>
          <TableCell
            colSpan={10}
            className="border-t border-gray-200 py-4"
            variant="bottom"
          >
            <div className="bg-white p-3 rounded-md border border-gray-200">
              {renderEditableNotesField()}
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
