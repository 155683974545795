import { useLazyQuery, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useOutlet, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CompanyUsersScreenFragment,
  Role,
  UserStatus,
  UsersTableRowFragment,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { formatList } from "~/common/format-list"
import type { PaginationFragment } from "~/common/pagination"
import {
  companyUsersDetailPath,
  companyUsersEditPath,
  companyUsersInvitePath,
  usersDetailPath,
  usersEditPath,
  usersInvitePath,
} from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { pickNonDefaults } from "~/common/zod-changed-defaults"
import { URLParamsSerializer } from "~/common/zod-search-params"
import ArrowRightIcon from "~/images/icons/arrow-right"
import threeDotIcon from "~/images/icons/three-dot-icon"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { ActiveFilters } from "~/search/active-filters"
import { NoResults } from "~/search/no-results"
import { SearchInput } from "~/search/search-input"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { FilterButton } from "~/ui/filter-button"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Heading } from "~/ui/heading"
import { InfiniteLoadMore } from "~/ui/infinite-load-more"
import {
  StyledDropdown,
  StyledDropdownInner,
  StyledDropdownItemWrapper,
} from "~/ui/styled-dropdown"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { useToast } from "~/ui/use-toast"
import { ArchiveUserModal } from "~/users/archive-user-modal"
import { RemoveCompanyUserModal } from "./remove-company-user-modal"
import { UserAvatar } from "./user-avatar"
import { UserRole, userRoleLabel } from "./user-role"

gql(/* GraphQL */ `
  fragment UsersTableRow on User {
    id
    firstName
    lastName
    email
    role
    title
    status
    companies {
      edges {
        node {
          name
        }
      }
    }
    ...UserAvatar
  }
`)

const statusToHumanReadable = (status: UserStatus) => {
  switch (status) {
    case UserStatus.Active:
      return "Active"
    case UserStatus.Archived:
      return "Archived"
    case UserStatus.Pending:
      return "Pending"
    default:
      const exhaustiveCheck: never = status
      throw new Error(`Unhandled case: ${exhaustiveCheck}`)
  }
}

const UserCompanies = ({ companies }: { companies: Array<string> }) => {
  const firstCompany = companies[0]
  const restCompanies = companies.slice(1)

  if (companies.length === 0) {
    return null
  }

  return (
    <div className="flex gap-1 items-center">
      <span key={firstCompany} className="truncate">
        {firstCompany}
      </span>
      {restCompanies.length > 0 && (
        <span className="text-gray-99 text-xs truncate">
          + {restCompanies.length} more
        </span>
      )}
    </div>
  )
}

type UsersTableProps = {
  users: Array<UsersTableRowFragment>
  isLoading: boolean
  pageInfo: PaginationFragment | undefined
  onLoadMore: (after: string) => void
  company?: CompanyUsersScreenFragment
  viewerIsCompanyAdmin?: boolean
}

const UsersTable = ({
  users,
  isLoading = false,
  pageInfo,
  onLoadMore,
  company,
  viewerIsCompanyAdmin = false,
}: UsersTableProps) => {
  const [archiveUserModalUser, setArchiveUserModalUser] =
    useState<UsersTableRowFragment>()
  const [removeUserModalUser, setRemoveUserModalUser] =
    useState<UsersTableRowFragment>()

  const navigate = useNavigate()

  return (
    <>
      <Table layout="fixed">
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">User</TableHead>
            <TableHead className="min-w-[275px]">Email Address</TableHead>
            <TableHead className="min-w-[180px]">Title</TableHead>
            <TableHead className="w-[150px]">Role</TableHead>
            {!company && <TableHead>Company</TableHead>}
            <TableHead className="w-24">Status</TableHead>
            <TableHead className="w-24">Actions</TableHead>
            <TableHead className="w-[48px]"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user.id}
              onClick={() => {
                let next = company
                  ? companyUsersDetailPath({
                      slug: company.slug,
                      userId: user.id,
                    })
                  : usersDetailPath({ userId: user.id })

                navigate(next)
              }}
            >
              <TableCell>
                <div className="flex items-center gap-2">
                  <UserAvatar user={user} />
                  <div className="truncate">
                    {user.firstName} {user.lastName}
                  </div>
                </div>
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.title}</TableCell>
              <TableCell>
                <UserRole {...user} />
              </TableCell>
              {!company && (
                <TableCell>
                  <UserCompanies
                    companies={user.companies.edges.map(
                      (edge) => edge.node.name
                    )}
                  />
                </TableCell>
              )}
              <TableCell>{statusToHumanReadable(user.status)}</TableCell>
              <TableCell>
                <ActionsMenu
                  user={user}
                  onArchiveClick={() => setArchiveUserModalUser(user)}
                  onRemoveClick={() => setRemoveUserModalUser(user)}
                  company={company}
                  isCompanyAdmin={viewerIsCompanyAdmin}
                />
              </TableCell>
              <TableCell>
                <Link
                  to={
                    company
                      ? companyUsersDetailPath({
                          slug: company.slug,
                          userId: user.id,
                        })
                      : usersDetailPath({ userId: user.id })
                  }
                >
                  <span className="sr-only">View</span>
                  <img {...ArrowRightIcon} alt="" />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <InfiniteLoadMore
        onEndReached={() => {
          if (pageInfo?.endCursor) {
            onLoadMore(pageInfo.endCursor)
          }
        }}
        canLoadMore={!isLoading && (pageInfo?.hasNextPage ?? false)}
        loadingText="Loading more users..."
        loading={isLoading && users.length > 0}
      />

      <ArchiveUserModal
        onClose={() => setArchiveUserModalUser(undefined)}
        isOpen={archiveUserModalUser !== undefined}
        user={archiveUserModalUser}
      />

      <RemoveCompanyUserModal
        onClose={() => setRemoveUserModalUser(undefined)}
        isOpen={removeUserModalUser !== undefined}
        user={removeUserModalUser}
        company={company}
      />
    </>
  )
}

const ActionsMenu = ({
  company,
  user,
  onArchiveClick,
  onRemoveClick,
  isCompanyAdmin,
}: {
  user: UsersTableRowFragment
  company?: CompanyUsersScreenFragment
  onArchiveClick: () => void
  onRemoveClick: () => void
  isCompanyAdmin: boolean
}) => {
  const [runMutation] = useSafeMutation(ARCHIVE_USER_MUTATION)
  const [userResendInvite] = useSafeMutation(USER_RESEND_INVITE)
  const { toast } = useToast()

  const restoreUser = async () => {
    const { errors } = await runMutation({
      variables: { input: { id: user.id } },
    })

    if (errors) {
      toast({ title: "Couldn't restore user", variant: "destructive" })
    } else {
      toast({ title: "User has been restored" })
    }
  }

  const onResendInviteClick = async () => {
    const { data } = await userResendInvite({
      variables: {
        input: {
          userId: user.id,
        },
      },
    })

    if (data?.userResendInvite.email) {
      toast({ title: `Invite sent to ${data.userResendInvite.email}` })
    } else {
      toast({
        title: "Error",
        description: "Error inviting user",
        variant: "destructive",
      })
    }
  }

  const isActiveOrPending =
    user.status === UserStatus.Active || user.status === UserStatus.Pending

  const isPending = user.status === UserStatus.Pending
  const isArchived = user.status === UserStatus.Archived

  return (
    <StyledDropdown
      trigger={
        <div className="h-6 flex items-center hover:bg-gray-100 rounded px-2">
          <img {...threeDotIcon} alt="Actions icon" />
        </div>
      }
    >
      {isActiveOrPending && !company && (
        <StyledDropdownItemWrapper asChild>
          <Button
            variant="ghost"
            size="sm"
            className="justify-start text-xs-plus h-7"
            onClick={onArchiveClick}
          >
            <div className="pl-4">Archive</div>
          </Button>
        </StyledDropdownItemWrapper>
      )}
      {isArchived && (
        <StyledDropdownItemWrapper asChild>
          <Button
            variant="ghost"
            size="sm"
            className="justify-start text-xs-plus h-7"
            onClick={restoreUser}
          >
            <div className="pl-4">Restore</div>
          </Button>
        </StyledDropdownItemWrapper>
      )}
      <StyledDropdownItemWrapper asChild>
        <Link
          to={
            company
              ? companyUsersEditPath({
                  slug: company.slug,
                  userId: user.id,
                })
              : usersEditPath({ userId: user.id })
          }
        >
          <StyledDropdownInner title="Edit" className="text-xs-plus" />
        </Link>
      </StyledDropdownItemWrapper>
      {isPending && (
        <StyledDropdownItemWrapper asChild>
          <Button
            variant="ghost"
            size="sm"
            className="justify-start text-xs-plus h-7"
            onClick={onResendInviteClick}
          >
            <div className="pl-4">Send Invite</div>
          </Button>
        </StyledDropdownItemWrapper>
      )}
      {isCompanyAdmin && (
        <StyledDropdownItemWrapper asChild>
          <Button
            variant="ghost"
            size="sm"
            className="justify-start text-xs-plus h-7"
            onClick={onRemoveClick}
          >
            <div className="pl-4">Remove</div>
          </Button>
        </StyledDropdownItemWrapper>
      )}
    </StyledDropdown>
  )
}

const formSchema = z.object({
  search: z.string(),
  roles: z.array(z.nativeEnum(Role)),
  statuses: z.array(z.nativeEnum(UserStatus)),
  companies: z.array(z.string()),
})

type FormValues = z.infer<typeof formSchema>

const defaultValues: FormValues = {
  search: "",
  roles: [],
  statuses: [UserStatus.Active],
  companies: [],
}

const urlSerializer = new URLParamsSerializer(formSchema, defaultValues)

export const UsersScreen = ({
  company,
  viewerIsCompanyAdmin = false,
}: {
  company?: CompanyUsersScreenFragment
  viewerIsCompanyAdmin?: boolean
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { viewer } = useViewer()

  let roleOptions: Array<{ label: string; value: Role }> = [
    { label: "Client", value: Role.Client },
    { label: "Creative", value: Role.Creative },
    { label: "Workweek Team", value: Role.WorkweekTeam },
    { label: "Workweek Admin", value: Role.WorkweekAdmin },
  ]

  const statusOptions: Array<{ label: string; value: UserStatus }> = [
    { label: "Active", value: UserStatus.Active },
    { label: "Pending", value: UserStatus.Pending },
    ...(company == null
      ? [{ label: "Archived", value: UserStatus.Archived }]
      : []),
  ]

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formSchema.parse({
      search: searchParams.get("search") ?? "",
      roles: searchParams.getAll("role") ?? [],
      statuses:
        searchParams.getAll("status").length > 0
          ? searchParams.getAll("status")
          : [UserStatus.Active],
      companies: searchParams.getAll("company") ?? [],
    }),
  })

  const { watch, setValue } = form

  const formData = watch()

  useEffect(() => {
    const newSearchParams = urlSerializer.serialize(formData)
    if (newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [formData, searchParams, setSearchParams])

  const nonDefaultValues = pickNonDefaults(formSchema, defaultValues, formData)
  const activeFiltersCount = Object.keys(nonDefaultValues).filter(
    (key) => key !== "search"
  ).length

  const [debouncedUserSearch] = useDebounce(formData.search, 200)

  const {
    data: currentData,
    previousData,
    error,
    loading,
    fetchMore: fetchMoreUsers,
  } = useQuery(USERS_QUERY_DOCUMENT, {
    variables: {
      search: debouncedUserSearch,
      roles: formData.roles.length > 0 ? formData.roles : undefined,
      statuses: formData.statuses.length > 0 ? formData.statuses : undefined,
      companies: company
        ? [company.id]
        : formData.companies.length > 0
        ? formData.companies
        : undefined,
    },
    notifyOnNetworkStatusChange: true,
  })

  const clearSearch = () => {
    setValue("search", "")
    setValue("roles", [])
    setValue("statuses", [])
    setValue("companies", [])
  }

  const [companyQuery, setCompanyQuery] = useState("")
  const [debouncedCompanySearch] = useDebounce(companyQuery, 200)

  const viewerIsAdmin = viewer?.role === Role.WorkweekAdmin

  const data = currentData || previousData
  const users = data?.users.edges.map((edge) => edge.node) ?? []

  const [
    sendQuery,
    {
      data: currentCompaniesData,
      previousData: previousCompaniesData,
      loading: companiesLoading,
    },
  ] = useLazyQuery(COMPANIES_QUERY_DOCUMENT, {
    variables: {
      search: debouncedCompanySearch,
    },
  })

  const companiesData = currentCompaniesData || previousCompaniesData
  const companies =
    companiesData?.companies.edges.map((edge) => edge.node) ?? []

  const {
    data: filteredCompaniesData,
    previousData: previousFilteredCompaniesData,
  } = useQuery(GET_COMPANIES_QUERY_DOCUMENT, {
    variables: {
      ids: formData.companies,
    },
  })

  const currentFilteredCompaniesData =
    filteredCompaniesData || previousFilteredCompaniesData

  const filteredCompanies =
    currentFilteredCompaniesData?.nodes.map((node) => {
      invariant(node?.__typename === "Company")
      return node
    }) ?? []

  const companyNameFromId = (id: string) => {
    const company = filteredCompanies.find((company) => company?.id === id)
    return company?.name
  }

  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }))

  const navigate = useNavigate()
  const outlet = useOutlet()

  if (error) {
    return <GraphqlError error={error} />
  }

  return (
    <TablePageLayout rightSideSlot={outlet}>
      <div className="px-4 space-y-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Form {...form}>
              <form className="flex items-center gap-2">
                <FormField
                  control={form.control}
                  name="search"
                  render={({ field, fieldState }) => (
                    <SearchInput placeholder="Search Users" {...field} />
                  )}
                />

                <FormField
                  control={form.control}
                  name="roles"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="sr-only">Filter by Role</FormLabel>
                      <FormControl>
                        <FilterButton
                          text="Filter by Role"
                          options={roleOptions}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="statuses"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="sr-only">
                        Filter by Status
                      </FormLabel>
                      <FormControl>
                        <FilterButton
                          text="Filter by Status"
                          options={statusOptions}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                {company == null && (
                  <FormField
                    control={form.control}
                    name="companies"
                    render={({ field }) => (
                      <FormItem className="space-y-0">
                        <FormLabel className="sr-only">
                          Filter by Company
                        </FormLabel>
                        <FormControl>
                          <FilterButton
                            typeAhead
                            isLoading={companiesLoading}
                            onOpen={() => sendQuery()}
                            text="Filter by Company"
                            options={companyOptions}
                            {...field}
                            onQueryChange={(query) => {
                              setCompanyQuery(query)
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                )}
              </form>
            </Form>
          </div>
          {viewerIsAdmin && !company && (
            <Button onClick={() => navigate(usersInvitePath({}))}>
              Invite Users
            </Button>
          )}
          {(viewerIsCompanyAdmin || viewerIsAdmin) && company && (
            <Button
              onClick={() =>
                navigate(companyUsersInvitePath({ slug: company.slug }))
              }
            >
              Invite Users
            </Button>
          )}
        </div>

        {activeFiltersCount > 0 && (
          <div className="bg-gray-50 rounded-lg p-5 py-4 flex items-center gap-6">
            <Heading
              title="Filters applied"
              count={activeFiltersCount}
              className="mb-0"
            />

            {nonDefaultValues.roles && nonDefaultValues.roles.length > 0 ? (
              <ActiveFilters
                label="Roles"
                values={formatList(
                  formData.roles.map((value) => userRoleLabel(value))
                )}
                onClear={() => setValue("roles", [])}
              />
            ) : null}

            {nonDefaultValues.statuses &&
            nonDefaultValues.statuses.length > 0 ? (
              <ActiveFilters
                label="Status"
                values={formData.statuses
                  .map((value) => statusToHumanReadable(value))
                  .join(", ")}
                onClear={() => setValue("statuses", [])}
              />
            ) : null}

            {formData.companies.length > 0 && (
              <ActiveFilters
                label="Company"
                values={formData.companies
                  .map((id) => companyNameFromId(id))
                  .join(", ")}
                onClear={() => setValue("companies", [])}
              />
            )}
          </div>
        )}
      </div>

      {users.length > 0 && (
        <UsersTable
          users={users}
          isLoading={loading}
          onLoadMore={(after) =>
            fetchMoreUsers({
              variables: { usersCursor: after },
            })
          }
          pageInfo={data?.users.pageInfo}
          company={company}
          viewerIsCompanyAdmin={viewerIsCompanyAdmin}
        />
      )}
      {!loading && !error && users.length === 0 && (
        <NoResults
          title="No Users Found"
          description="Clear your search results to view all users"
          onClearSearch={() => clearSearch()}
        />
      )}
    </TablePageLayout>
  )
}

const USERS_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query Users(
    $search: String
    $roles: [Role!]
    $statuses: [UserStatus!]
    $companies: [String!]
    $usersCursor: String
  ) {
    users(
      filters: {
        search: $search
        roles: $roles
        statuses: $statuses
        companies: $companies
      }
      first: 20
      after: $usersCursor
    ) {
      edges {
        node {
          id
          ...UsersTableRow
          companies {
            edges {
              role
              node {
                name
              }
            }
          }
        }
      }
      pageInfo {
        ...Pagination
      }
    }
  }
`)

const COMPANIES_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query Companies($search: String, $first: Int = 10) {
    companies(search: $search, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

const GET_COMPANIES_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query GetCompanies($ids: [ID!]) {
    nodes(ids: $ids) {
      ... on Company {
        id
        name
      }
    }
  }
`)

const ARCHIVE_USER_MUTATION = gql(`
  mutation RestoreUser($input: UserRestoreInput!) {
    userRestore(input: $input) {
      user {
        id
        status
      }
    }
  }
`)

const USER_RESEND_INVITE = gql(`
  mutation UserResendInvite($input: UserResendInviteInput!) {
    userResendInvite(input: $input) {
      email
    }
  }
`)
