import { Outlet, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { GraphqlError } from "~/ui/errors"
import { CompanyProvider } from "./company-context"

export const companyQuery = gql(/* GraphQL */ `
  query RequireCompanyQuery($slug: String!) {
    company(slug: $slug) {
      id
      ...CompanyContext
    }
  }
`)

export const RequireCompany = () => {
  const params = useParams()
  const companySlug = params.slug
  invariant(companySlug)

  const result = useQuery(companyQuery, { variables: { slug: companySlug } })

  if (result.loading) {
    return null
  }
  if (result.error) {
    return <GraphqlError error={result.error} />
  }

  invariant(result.data, "Data should be available at this point")
  if (!result.data.company) {
    return <>Missing company</>
  }

  return (
    <CompanyProvider company={result.data.company}>
      <Outlet />
    </CompanyProvider>
  )
}
