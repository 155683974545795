import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import type { Params } from "static-path"
import invariant from "tiny-invariant"
import { z } from "zod"
import { gql } from "~/__generated__"
import { CompanyEditPane_CompanyFragment } from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { companiesPath, companyProfileEditPath } from "~/common/paths"
import { useDocumentTitle } from "~/common/use-document-title"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { ImageField } from "~/fields/image-field"
import { TextField } from "~/fields/text-field"
import { TextareaField } from "~/fields/textarea-field"
import { StyledFormMessage } from "~/forms/styled-form-message"
import { AddressAutoCompleteInput } from "~/ui/address-autocomplete"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { Form, FormControl, FormItem, FormLabel } from "~/ui/form"
import { Input } from "~/ui/input"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"
import { CompanyCategoriesField } from "./company-categories-field"

gql(/* GraphQL */ `
  fragment CompanyEditPane_company on Company {
    id
    name
    description
    website
    profileStatus
    categories
    image {
      id
    }
    address {
      id
      ...AutocompleteAddress
    }
  }
`)

const query = gql(/* GraphQL */ `
  query CompanyEditPaneQuery($slug: String!) {
    company(slug: $slug) {
      id
      ...CompanyEditPane_company
    }
  }
`)

export const CompanyEditScreen = () => {
  const params = useParams<Params<typeof companyProfileEditPath.pattern>>()
  invariant(params.slug)

  const result = useQuery(query, {
    variables: { slug: params.slug },
  })
  const company = result.data?.company

  useDocumentTitle(company ? `Edit ${company.name}` : null)

  return (
    <div className="flex flex-col p-[40px]">
      {result.loading ? (
        <LoadingIndicatorCentered />
      ) : result.error ? (
        <GraphqlError error={result.error} />
      ) : company ? (
        <CompanyForm company={company} />
      ) : null}
    </div>
  )
}

const addressSchema = z.object({
  id: z.string().nullable().optional(),
  administrativeAreaLevel1: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  formattedAddress: z.string(),
  lat: z.number(),
  lng: z.number(),
  locality: z.string().nullable().optional(),
  placeId: z.string(),
  postalCode: z.string().nullable().optional(),
  premise: z.string().nullable().optional(),
  route: z.string().nullable().optional(),
  streetNumber: z.string().nullable().optional(),
  subpremise: z.string().nullable().optional(),
})

const formSchema = z.object({
  description: z.string().optional(),
  website: z.string().optional(),
  imageId: z.string().nullable(),
  address: addressSchema.nullable(),
  categories: z.array(z.string()).min(1, "Please select at least one category"),
})

const mutation = gql(/* GraphQL */ `
  mutation CompanyEditPaneUpdateMutation($input: CompanyUpdateInput!) {
    companyUpdate(input: $input) {
      company {
        id
        ...CompanyEditPane_company
      }
    }
  }
`)

const CompanyForm: React.FC<{
  company: CompanyEditPane_CompanyFragment
}> = ({ company }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      description: company.description ?? "",
      website: company.website ?? "",
      imageId: company.image?.id ?? null,
      address: company.address ?? null,
      categories: company.categories?.length ? company.categories : [""],
    },
  })

  const [exec, result] = useSafeMutation(mutation)
  useValidationErrors(form.setError, result)
  const navigate = useNavigate()
  const { toast } = useToast()
  const { refetch: refetchViewer } = useViewer()

  const performUpdate = async (values: z.infer<typeof formSchema>) => {
    const result = await exec({
      variables: {
        input: {
          id: company.id,
          companyInput: {
            ...values,
          },
        },
      },
    })
    if (!result.errors) {
      refetchViewer().then(() => {
        toast({
          title: "Company updated",
          variant: "default",
        })
        navigate(companiesPath({}))
      })
    }
  }

  const incompleteCompany =
    company?.description == null ||
    company?.website == null ||
    company?.categories == null

  return (
    <Form {...form}>
      {incompleteCompany && (
        <div className="mb-8">
          <StyledFormMessage>
            <Text variant="body-14">
              Your company is missing some required information. Please fill out
              the form below.
            </Text>
          </StyledFormMessage>
        </div>
      )}
      <form onSubmit={form.handleSubmit(performUpdate)}>
        <div className="grid grid-cols-[330px,1fr] gap-8">
          <div>
            <Text variant="mini-caps" className="mb-4">
              Company Branding
            </Text>
            <ImageField label="" control={form.control} name="imageId" />
          </div>
          <div className="bg-gray-f9 p-6 rounded-lg flex flex-col">
            <div className="space-y-4">
              <FormItem>
                <FormLabel>Company Name</FormLabel>
                <FormControl>
                  <Input readOnly={true} disabled={true} value={company.name} />
                </FormControl>
              </FormItem>
              <TextareaField
                control={form.control}
                name="description"
                label="Company Description*"
                rows={10}
              />
              <CompanyCategoriesField
                control={form.control}
                name="categories"
                label="Company Categories (select all that apply)*"
              />
              <Controller
                name="address"
                control={form.control}
                defaultValue={company.address ?? null}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Company Address</FormLabel>
                    <AddressAutoCompleteInput
                      address={field.value}
                      onChangeAddress={(address) => {
                        field.onChange(address)
                      }}
                    />
                  </FormItem>
                )}
              />
              <TextField
                control={form.control}
                name="website"
                label="Company Website*"
                type="url"
              />
            </div>

            <hr className="my-8" />

            <Button
              type="submit"
              disabled={result.loading}
              className="font-light"
            >
              Save Profile
            </Button>
          </div>
        </div>
      </form>
    </Form>
  )
}
