import { useEffect } from "react"
import { Outlet, ScrollRestoration, useMatches } from "react-router-dom"
import { PostReadProvider } from "~/feed/PostReadContext"
import { Toaster } from "~/ui/toaster"
import { RootFlashMessages } from "./root-flash-messages"

export const RootLayout = () => {
  const matches = useMatches()
  const handle = matches[matches.length - 1].handle as
    | { title: string }
    | undefined

  useEffect(() => {
    if (handle?.title) {
      document.title = `${handle.title} · Workweek Partner Platform`
    }
  }, [handle])

  return (
    <>
      <PostReadProvider>
        <Outlet />
        <ScrollRestoration />
        <Toaster />
        <RootFlashMessages />
      </PostReadProvider>
    </>
  )
}
