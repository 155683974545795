import { CampaignDeliverableType } from "~/__generated__/graphql"
import gasStove from "~/images/deliverable-type-icons/gas-stove"
import idCard from "~/images/deliverable-type-icons/id-card"
import laptop2 from "~/images/deliverable-type-icons/laptop-2"
import likeTag from "~/images/deliverable-type-icons/like-tag"
import mic from "~/images/deliverable-type-icons/mic"
import noteBook from "~/images/deliverable-type-icons/note-book"
import receiptCheck from "~/images/deliverable-type-icons/receipt-check"
import vision from "~/images/deliverable-type-icons/vision"
import { ImgProps } from "~/images/img-props"

export const DeliverableType = ({
  deliverableType,
  showIcon = true,
}: {
  deliverableType: CampaignDeliverableType
  showIcon?: boolean
}) => {
  return (
    <>
      {showIcon && <DeliverableTypeIcon deliverableType={deliverableType} />}{" "}
      {deliverableTypeMap[deliverableType].label}
    </>
  )
}

// https://www.figma.com/design/CR7jT0vfa2FsjJ5hTeJbvm/Workweek---Ads?node-id=587-12177&t=MAtpTuVMYAllAPg2-4
const deliverableTypeMap: Record<
  CampaignDeliverableType,
  { img: ImgProps; label: string }
> = {
  [CampaignDeliverableType.NewsletterAds]: {
    img: receiptCheck,
    label: "Newsletter",
  },
  [CampaignDeliverableType.DeepDives]: { img: noteBook, label: "Deep Dive" },
  [CampaignDeliverableType.LeadGen]: { img: vision, label: "Lead Generation" },
  [CampaignDeliverableType.Podcasts]: { img: mic, label: "Podcasts/Youtube" },
  [CampaignDeliverableType.VirtualEvents]: {
    img: laptop2,
    label: "Virtual Event",
  },
  [CampaignDeliverableType.LiveEvents]: { img: idCard, label: "Live Event" },
  [CampaignDeliverableType.BrandedArticles]: {
    img: gasStove,
    label: "Branded Article",
  },
  [CampaignDeliverableType.SocialPosts]: { img: likeTag, label: "Social" },
  [CampaignDeliverableType.Other]: { img: vision, label: "Other" },
}

const DeliverableTypeIcon = ({
  deliverableType,
}: {
  deliverableType: CampaignDeliverableType
}) => {
  return (
    <img
      {...deliverableTypeMap[deliverableType].img}
      alt=""
      className="inline-block align-text-bottom"
    />
  )
}
