import { useQuery } from "@apollo/client"
import { useOutlet, useParams } from "react-router-dom"
import { Params } from "static-path"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { CampaignDeliverableType } from "~/__generated__/graphql"
import { GoBack } from "~/campaigns/go-back"
import { formatDate } from "~/common/date-formatting"
import { gqlMatchOptional } from "~/common/gql-match"
import * as paths from "~/common/paths"
import { useGoBack } from "~/common/use-go-back"
import { CompanyLogo } from "~/companies/company-logo"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import Text from "~/ui/typography"

const query = gql(/* GraphQL */ `
  query CampaignDeliverableDetailScreenQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on CampaignDeliverable {
        id
        deliverableName
        publishDateCanonical
        deliverableType
        deliverableContentHtml
        ctaButton
        ctaUrl
        placement

        productBrief {
          id
          name
          company {
            id
            name
            ...CompanyLogo
          }
        }
        creatorBrand {
          id
          name
          ctaColor
          ctaTextColor
        }
      }
    }
  }
`)

export const ContentLibraryDetailScreen = () => {
  const outlet = useOutlet()
  const params = useParams() as Params<
    typeof paths.companyContentReferenceDetailPath.pattern
  >
  const goBack = useGoBack(
    paths.companyContentLibraryPath({
      slug: params.slug,
    })
  )

  const result = useQuery(query, {
    variables: { id: params.campaignDeliverableId },
  })

  const campaignDeliverable = gqlMatchOptional(
    result.data?.node,
    "CampaignDeliverable"
  )

  const deliverableTypeNames = {
    [CampaignDeliverableType.BrandedArticles]: "Branded Articles",
    [CampaignDeliverableType.DeepDives]: "Deep Dives",
    [CampaignDeliverableType.NewsletterAds]: "Newsletter Ads",
    [CampaignDeliverableType.LeadGen]: "Lead Generation",
    [CampaignDeliverableType.LiveEvents]: "Live Events",
    [CampaignDeliverableType.VirtualEvents]: "Virtual Events",
    [CampaignDeliverableType.Podcasts]: "Podcasts",
    [CampaignDeliverableType.SocialPosts]: "Social Posts",
    [CampaignDeliverableType.Other]: "Other",
  }

  if (!campaignDeliverable) {
    return null
  }

  const isNewsletter =
    campaignDeliverable?.deliverableType ===
    CampaignDeliverableType.NewsletterAds
  const isPlacementPrimaryOrSecondary =
    campaignDeliverable?.placement === "Primary" ||
    campaignDeliverable?.placement === "Secondary"

  invariant(campaignDeliverable?.productBrief?.company, "Expected company")

  return (
    <>
      <GoBack onClick={goBack} />
      {result.error ? <GraphqlError error={result.error} /> : null}
      {campaignDeliverable != null ? (
        <TablePageLayout padding={false} rightSideSlot={outlet}>
          <div className="flex flex-1 flex-col">
            <div className="grid grid-cols-[minmax(475px,1fr)_475px] p-[40px] gap-[40px] items-start overflow-auto">
              <div className="flex flex-col flex-1">
                <div className="flex flex-col flex-1">
                  <div className="flex flex-col bg-gray-f9 rounded-lg mb-8 p-8">
                    <div className="flex flex-col space-y-9">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                          <CompanyLogo
                            company={campaignDeliverable?.productBrief?.company}
                            size="32"
                          />
                          <Text variant="body-14-medium">
                            {campaignDeliverable?.productBrief?.company.name}
                          </Text>
                        </div>
                        {/* <div className="flex items-center gap-2">
                          <img {...medal} alt="" className="w-6 h-6" />
                          <span className="border rounded-sm border-gray-600 px-2 py-1 text-xs">
                            Product Reference
                          </span>
                        </div> */}
                      </div>
                      <div className="space-y-4">
                        <Text variant="body-24-medium">
                          {campaignDeliverable?.deliverableName}
                        </Text>

                        <div>
                          <div>
                            <Text variant="body-14" className="text-gray-400">
                              Published:
                            </Text>{" "}
                            <Text variant="body-14">
                              {campaignDeliverable.publishDateCanonical
                                ? formatDate(
                                    campaignDeliverable?.publishDateCanonical,
                                    "MMM d, yyyy"
                                  )
                                : "-"}
                            </Text>
                          </div>
                          <div>
                            <Text variant="body-14" className="text-gray-400">
                              Creator Brand:
                            </Text>{" "}
                            <Text variant="body-14">
                              {campaignDeliverable?.creatorBrand.name}
                            </Text>
                          </div>
                          <div>
                            <Text variant="body-14" className="text-gray-400">
                              Content Type:
                            </Text>{" "}
                            <Text variant="body-14">
                              {campaignDeliverable?.deliverableType &&
                                deliverableTypeNames[
                                  campaignDeliverable.deliverableType as keyof typeof deliverableTypeNames
                                ]}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Text variant="body-18-medium">Ad Copy</Text>
                    </div>
                    <div className="mt-4">
                      <Text>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              campaignDeliverable?.deliverableContentHtml ?? "",
                          }}
                        ></div>
                      </Text>
                    </div>
                    {isNewsletter && isPlacementPrimaryOrSecondary && (
                      <div className="mt-6">
                        <Button
                          style={{
                            background:
                              campaignDeliverable.creatorBrand.ctaColor ??
                              "#000000",
                            color:
                              campaignDeliverable.creatorBrand.ctaTextColor ??
                              "#ffffff",
                          }}
                        >
                          {campaignDeliverable?.ctaButton}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TablePageLayout>
      ) : null}
    </>
  )
}
