import { useLazyQuery } from "@apollo/client"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { useDebouncedCallback } from "use-debounce"
import { Role, UserStatus } from "~/__generated__/graphql"
import { dmPath } from "~/common/paths"
import { MENTION_USER_QUERY_DOCUMENT } from "~/post-composer/useMentionDataSource"
import { Button } from "~/ui/button"
import { Dialog, DialogContent } from "~/ui/dialog"

type UserOption = {
  value: string
  label: string
}

export const StartDmModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}) => {
  const navigate = useNavigate()
  const [getCollaboratorSearch] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: {
      excludeSelf: true,
      statuses: [UserStatus.Active],
      roles: [Role.Client, Role.WorkweekAdmin, Role.WorkweekTeam],
    },
  })

  const _loadOptions = useCallback(
    (query: string, callback: (options: UserOption[]) => void) => {
      getCollaboratorSearch({
        variables: { query },
      }).then(({ data }) => {
        if (!data) {
          callback([])
        } else {
          callback(
            data.users.nodes.map((u) => ({
              value: u.id,
              label: u.name || "",
            }))
          )
        }
      })
    },
    [getCollaboratorSearch]
  )
  const loadOptions = useDebouncedCallback(_loadOptions, 400)

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="mb-6 text-center text-xl font-medium">
          Compose New Message
        </div>

        <div>
          <div className="flex mb-4 gap-4">
            <AsyncSelect
              classNamePrefix="select"
              className="flex-1"
              placeholder="Who are you messaging..."
              loadOptions={loadOptions}
              value={null}
              onChange={(selection) => {
                if (selection?.value) {
                  setIsOpen(false)
                  navigate(dmPath({ otherUserId: selection.value }))
                }
              }}
              styles={{
                control: (baseStyles, _state) => ({
                  ...baseStyles,
                  outline: "none !important",
                  boxShadow: "none",
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  fontSize: 16,
                  borderRadius: 8,
                }),
              }}
            />
          </div>

          <div className="h-[1px] bg-gray-300 my-8" />

          <div className="flex justify-end">
            <Button
              type="button"
              variant="outline"
              className="px-10"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
