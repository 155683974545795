import { useLazyQuery, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useOutlet, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CampaignsTableRowFragment,
  CompanyContextFragment,
} from "~/__generated__/graphql"
import { formatDate } from "~/common/date-formatting"
import { campaignDetailPath, companyProfilePath } from "~/common/paths"
import { countNonDefaults } from "~/common/zod-changed-defaults"
import { URLParamsSerializer } from "~/common/zod-search-params"
import { useCompany } from "~/companies/company-context"
import { CompanyLogo } from "~/companies/company-logo"
import { FilterField } from "~/fields/filter-field"
import arrowRight from "~/images/icons/arrow-right"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { ActiveFilters } from "~/search/active-filters"
import { NoResults } from "~/search/no-results"
import { SearchInput } from "~/search/search-input"
import { GraphqlError } from "~/ui/errors"
import { FilterButton } from "~/ui/filter-button"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Heading } from "~/ui/heading"
import { InfiniteLoadMore } from "~/ui/infinite-load-more"
import { Link } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { Percent } from "../ui/percent"
import { CampaignDeliverables } from "./campaign-deliverables"

gql(/* GraphQL */ `
  fragment CampaignsTableRow on Campaign {
    id
    campaignName
    company {
      id
      name
      slug
      ...CompanyLogo
    }
    campaignDeliverablesCount
    campaignDeliverablesCompletedCount
    campaignDeliverablesLastPublishedOn
  }
`)

type CampaignsTableProps = {
  campaigns: Array<CampaignsTableRowFragment>
}

const CampaignsTable = ({ campaigns }: CampaignsTableProps) => {
  return (
    <Table className="border-spacing-y-0" layout="fixed">
      <TableHeader>
        <TableRow>
          <TableHead className="w-[316px]">Campaign Name</TableHead>
          <TableHead className="w-[150px]">Company</TableHead>
          <TableHead className="w-16">Deliverables</TableHead>
          <TableHead className="w-16">Complete</TableHead>
          <TableHead className="w-28">Last Published</TableHead>
          <TableHead className="w-24 text-right">Actions</TableHead>
          <TableHead className="w-[68px] text-right"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {campaigns.map((campaign) => (
          <React.Fragment key={campaign.id}>
            <CampaignRow campaign={campaign} />
            {/* spacer, note the spacing-y-0 */}
            <tr>
              <td colSpan={7} className="h-2"></td>
            </tr>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  )
}

const CampaignRow = ({ campaign }: { campaign: CampaignsTableRowFragment }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen((prev) => !prev)

  const tableProps = { variant: isOpen ? "top" : "default" } as const

  return (
    <>
      <TableRow
        key={campaign.id}
        onClick={toggleOpen}
        aria-label="View Campaign Deliverables"
      >
        <TableCell {...tableProps}>{campaign.campaignName}</TableCell>
        <TableCell {...tableProps}>
          <Link
            className="flex items-center gap-4"
            to={companyProfilePath({ slug: campaign.company.slug })}
            variant="unstyled"
          >
            <CompanyLogo company={campaign.company} />
            {campaign.company.name}
          </Link>
        </TableCell>
        <TableCell {...tableProps}>
          {campaign.campaignDeliverablesCount.toLocaleString()}
        </TableCell>
        <TableCell {...tableProps}>
          <Percent
            completed={campaign.campaignDeliverablesCompletedCount}
            total={campaign.campaignDeliverablesCount}
          />
        </TableCell>
        <TableCell {...tableProps}>
          {campaign.campaignDeliverablesLastPublishedOn
            ? formatDate(campaign.campaignDeliverablesLastPublishedOn)
            : "–"}
        </TableCell>
        <TableCell {...tableProps} className="text-right"></TableCell>
        <TableCell {...tableProps} className="text-right pe-4">
          <LinkButton
            to={campaignDetailPath({ campaignId: campaign.id })}
            variant="ghost"
            size="icon"
          >
            <img {...arrowRight} className="inline-block" alt="" />
            <span className="sr-only">View Campaign</span>
          </LinkButton>
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <TableCell
            colSpan={7}
            className="border-t border-gray-200 px-4 pt-4 pb-6"
            variant="bottom"
          >
            <CampaignDeliverables campaign={campaign} />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export const CompanyCampaignsScreen = () => {
  const { company } = useCompany()
  return <CampaignsScreen company={company} />
}

enum Completion {
  Incomplete = "0",
  Complete = "1",
}

const formSchema = z.object({
  search: z.string(),
  companies: z.array(z.string()),
  complete: z.array(z.nativeEnum(Completion)),
})

const formDefaults: z.infer<typeof formSchema> = {
  search: "",
  companies: [],
  complete: [Completion.Incomplete],
}

const serializer = new URLParamsSerializer(formSchema, formDefaults)

export const CampaignsScreen = ({
  company,
}: {
  company?: CompanyContextFragment
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: serializer.deserialize(searchParams),
  })

  const { watch, setValue } = form

  const formData = watch()

  useEffect(() => {
    const newSearchParams = serializer.serialize(formData)
    if (newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [formData, searchParams, setSearchParams])

  const activeFiltersCount = countNonDefaults(
    formSchema,
    formDefaults,
    formData
  )

  const [debouncedSearch] = useDebounce(formData.search, 200)

  let complete = null
  if (formData.complete.length === 1) {
    complete = formData.complete[0] === Completion.Complete ? true : false
  }

  const { data, previousData, loading, error, fetchMore } = useQuery(
    searchCampaignsQuery,
    {
      variables: {
        filter: {
          search: debouncedSearch,
          companies: company
            ? [company.id]
            : formData.companies.length > 0
            ? formData.companies
            : undefined,
          complete,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  const clearSearch = () => {
    setValue("search", "")
    setValue("companies", [])
  }

  const currentData = data || previousData
  const campaigns = currentData?.campaigns.edges.map((edge) => edge.node) ?? []
  const pageInfo = currentData?.campaigns.pageInfo

  const [companyQuery, setCompanyQuery] = useState("")
  const [debouncedCompanySearch] = useDebounce(companyQuery, 200)

  const [
    sendQuery,
    {
      data: currentCompaniesData,
      previousData: previousCompaniesData,
      loading: companiesLoading,
    },
  ] = useLazyQuery(companiesQuery, {
    variables: {
      search: debouncedCompanySearch,
    },
  })

  const companiesData = currentCompaniesData || previousCompaniesData
  const companies =
    companiesData?.companies.edges.map((edge) => edge.node) ?? []

  const {
    data: filteredCompaniesData,
    previousData: previousFilteredCompaniesData,
  } = useQuery(getCompaniesQuery, {
    variables: {
      ids: formData.companies,
    },
  })

  const currentFilteredCompaniesData =
    filteredCompaniesData || previousFilteredCompaniesData
  const filteredCompanies =
    currentFilteredCompaniesData?.nodes.map((node) => {
      invariant(node?.__typename === "Company")
      return node
    }) ?? []

  const companyNameFromId = (id: string) => {
    const company = filteredCompanies.find((company) => company?.id === id)
    return company?.name
  }

  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }))

  const activeSearch = activeFiltersCount > 0 || formData.search.length > 0
  const outlet = useOutlet()

  return (
    <TablePageLayout rightSideSlot={outlet}>
      <div className="px-4 space-y-8">
        <div className="flex items-center gap-2">
          <Form {...form}>
            <form className="flex items-center gap-2">
              <FormField
                control={form.control}
                name="search"
                render={({ field }) => (
                  <SearchInput placeholder="Search Campaigns" {...field} />
                )}
              />

              {!company && (
                <FormField
                  control={form.control}
                  name="companies"
                  render={({ field }) => (
                    <FormItem className="space-y-0">
                      <FormLabel className="sr-only">
                        Filter by Company
                      </FormLabel>
                      <FormControl>
                        <FilterButton
                          typeAhead
                          isLoading={companiesLoading}
                          onOpen={() => sendQuery()}
                          text="Filter by Company"
                          options={companyOptions}
                          {...field}
                          onQueryChange={(query) => {
                            setCompanyQuery(query)
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              )}

              <FilterField
                control={form.control}
                name="complete"
                options={[
                  { label: "Incomplete", value: Completion.Incomplete },
                  { label: "Complete", value: Completion.Complete },
                ]}
                text="Status"
              />
            </form>
          </Form>
        </div>

        {activeFiltersCount > 0 && (
          <div className="bg-gray-50 rounded-lg p-5 py-4 flex items-center gap-6">
            <Heading
              title="Filters applied"
              count={activeFiltersCount}
              className="mb-0"
            />

            {formData.companies.length > 0 && (
              <ActiveFilters
                label="Company"
                values={formData.companies
                  .map((id) => companyNameFromId(id))
                  .join(", ")}
                onClear={() => setValue("companies", [])}
              />
            )}
          </div>
        )}

        <Heading title="Campaigns" count={campaigns.length} />

        {error ? <GraphqlError error={error} /> : null}

        {campaigns.length > 0 && <CampaignsTable campaigns={campaigns} />}

        <InfiniteLoadMore
          onEndReached={() => {
            if (pageInfo?.endCursor) {
              fetchMore({
                variables: { campaignsCursor: pageInfo.endCursor },
              })
            }
          }}
          canLoadMore={!loading && (pageInfo?.hasNextPage ?? false)}
          loadingText="Loading more campaigns..."
          loading={loading && campaigns.length > 0}
        />

        {!loading && !error && campaigns.length === 0 && (
          <NoResults
            title="No Campaigns Found"
            description={
              activeSearch
                ? "Clear your search results to view all campaigns"
                : "We couldn't find any campaigns"
            }
            onClearSearch={activeSearch ? clearSearch : undefined}
          />
        )}
      </div>
    </TablePageLayout>
  )
}

export const searchCampaignsQuery = gql(/* GraphQL */ `
  query SearchCampaigns(
    $campaignsCursor: String
    $filter: CampaignFilterInput
  ) {
    campaigns(filter: $filter, after: $campaignsCursor, first: 20) {
      edges {
        node {
          id
          ...CampaignsTableRow
        }
      }

      pageInfo {
        ...Pagination
      }
    }
  }
`)

const companiesQuery = gql(/* GraphQL */ `
  query Companies($search: String, $first: Int = 10) {
    companies(search: $search, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

const getCompaniesQuery = gql(/* GraphQL */ `
  query GetCompanies($ids: [ID!]) {
    nodes(ids: $ids) {
      ... on Company {
        id
        name
      }
    }
  }
`)
