import { useLazyQuery } from "@apollo/client"
import { useState } from "react"
import { Link } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  MarkReadTarget,
  NotificationAction,
  NotificationsResultFragment,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { formatRelativeTime } from "~/common/date-formatting"
import {
  campaignDeliverablePath,
  campaignDetailGoalsPath,
  campaignDetailPath,
} from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import NotificationsIcon from "~/images/icons/notifications.svg?react"
import { InfiniteLoadMore } from "~/ui/infinite-load-more"
import { ButtonLink } from "~/ui/link"
import { LoadingIndicator } from "~/ui/loading-indicator"
import { StyledDropdown } from "~/ui/styled-dropdown"
import Text from "~/ui/typography"

gql(`
  fragment NotificationsResult on Notification {
    id
    action
    unread
    postedAt
    actor {
      ...UserAvatar
      fullName
    }
    source {
      ... on Campaign {
        id
        campaignName
      }
      ... on CampaignDeliverable {
        id
        deliverableName
        campaign {
          id
        }
      }
    }
  }
`)

const NOTIFICATIONS_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query Notifications($notificationsCursor: String) {
    notifications(first: 20, after: $notificationsCursor) {
      edges {
        node {
          ...NotificationsResult
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)

const markReadMutation = gql(/* GraphQL */ `
  mutation markRead($input: MarkReadInput!) {
    markRead(input: $input) {
      viewer {
        id
        unreadNotificationCount

        notifications(first: 50) {
          edges {
            node {
              ...NotificationsResult
            }
          }
        }
      }
    }
  }
`)

export const Notifications = () => {
  const { viewer } = useViewer()
  const [open, setOpen] = useState(false)

  const [execMarkRead] = useSafeMutation(markReadMutation)

  const [
    fetchNotifications,
    { data: currentData, previousData, loading, error, fetchMore },
  ] = useLazyQuery(NOTIFICATIONS_QUERY_DOCUMENT, {
    notifyOnNetworkStatusChange: true,
  })

  const data = currentData || previousData

  const notifications = data?.notifications.edges.map((e) => e.node) || []

  const onOpenChange = (open: boolean) => {
    if (open && !loading) {
      fetchNotifications()
    }
    setOpen(open)
  }

  const renderNotification = (notification: NotificationsResultFragment) => {
    if (notification.source?.__typename === "Campaign") {
      switch (notification.action) {
        case NotificationAction.AssignedToCampaign:
          return (
            <NotificationWrapper notification={notification}>
              You have been assigned to a new campaign:{" "}
              {notification.source?.campaignName}
            </NotificationWrapper>
          )
        case NotificationAction.CampaignGoalsNeeded:
          return (
            <NotificationWrapper notification={notification}>
              Add campaign goals for {notification.source?.campaignName}
            </NotificationWrapper>
          )
        case NotificationAction.CampaignIsReady:
          return (
            <NotificationWrapper notification={notification}>
              Your campaign: {notification.source?.campaignName} is ready to be
              kicked off
            </NotificationWrapper>
          )
        default:
          return <div>Unknown action: {notification.action}</div>
      }
    }

    if (notification.source?.__typename === "CampaignDeliverable") {
      switch (notification.action) {
        case NotificationAction.AssignedToDeliverable:
          return (
            <NotificationWrapper notification={notification}>
              You have been assigned to: {notification.source?.deliverableName}
            </NotificationWrapper>
          )
        case NotificationAction.ReadyForCreatorApproval:
          return (
            <NotificationWrapper notification={notification}>
              {notification.source?.deliverableName} is ready for review
            </NotificationWrapper>
          )
        case NotificationAction.ReadyForAccountManagerApproval:
          return (
            <NotificationWrapper notification={notification}>
              {notification.source?.deliverableName} is ready for review
            </NotificationWrapper>
          )
        case NotificationAction.ReadyForClientApproval:
          return (
            <NotificationWrapper notification={notification}>
              {notification.source?.deliverableName} is ready for review
            </NotificationWrapper>
          )
        case NotificationAction.DeliverablesRejected:
          return (
            <NotificationWrapper notification={notification}>
              {notification.actor?.fullName} rejected{" "}
              {notification.source?.deliverableName}
            </NotificationWrapper>
          )
        case NotificationAction.DeliverableReadyToBeScaffolded:
          return (
            <NotificationWrapper notification={notification}>
              {notification.source?.deliverableName} is ready to be scaffolded
            </NotificationWrapper>
          )
        case NotificationAction.DeliverableScaffolded:
          return (
            <NotificationWrapper notification={notification}>
              {notification.source?.deliverableName} has been scaffolded
            </NotificationWrapper>
          )
        case NotificationAction.DeliverableScheduled:
          return (
            <NotificationWrapper notification={notification}>
              {notification.source?.deliverableName} has been scheduled
            </NotificationWrapper>
          )
        case NotificationAction.DeliverablePublished:
          return (
            <NotificationWrapper notification={notification}>
              {notification.source?.deliverableName} has been published
            </NotificationWrapper>
          )
        default:
          return <div>Unknown action: {notification.action}</div>
      }
    }
  }

  const pathForNotification = (
    notification: NotificationsResultFragment
  ): string => {
    const { source, action } = notification

    if (!source) return "/"

    switch (source.__typename) {
      case "Campaign":
        if (action === NotificationAction.CampaignGoalsNeeded) {
          return campaignDetailGoalsPath({ campaignId: source.id })
        }
        return campaignDetailPath({ campaignId: source.id })

      case "CampaignDeliverable":
        return campaignDeliverablePath({
          campaignId: source.campaign.id,
          deliverableId: source.id,
        })

      default:
        return "/"
    }
  }

  const markRead = (notification: NotificationsResultFragment) => {
    execMarkRead({
      variables: {
        input: {
          target: MarkReadTarget.Notification,
          id: notification.id,
        },
      },
    })
  }

  const markAllRead = () => {
    execMarkRead({
      variables: {
        input: {
          target: MarkReadTarget.All,
        },
      },
    })
  }

  return (
    <StyledDropdown
      open={open}
      onOpenChange={onOpenChange}
      trigger={
        <div className="flex items-center gap-1">
          <div className="bg-foreground text-white rounded-full w-[19px] h-[19px] text-xs flex items-center justify-center">
            {viewer.unreadNotificationCount}
          </div>
          <NotificationsIcon />
        </div>
      }
      className="p-0 text-2xs leading-4 w-[302px] max-h-[calc(100vh-100px)] overflow-scroll"
    >
      <div className="border-b border-gray-d0 px-3 py-1 mb-2 flex items-center justify-between">
        <Text variant="body-10-bold" className="uppercase">
          Notifications
        </Text>
        {notifications.filter((n) => n.unread).length > 0 && (
          <ButtonLink
            onClick={markAllRead}
            variant="add"
            className="text-xs-plus"
          >
            <Text variant="body-10-medium">Mark all read</Text>
          </ButtonLink>
        )}
      </div>
      {loading && notifications.length === 0 ? (
        <div className="flex justify-center p-4">
          <LoadingIndicator />
        </div>
      ) : error ? (
        <div className="px-5 p-4">Error loading notifications</div>
      ) : notifications.length === 0 ? (
        <div className="px-5 p-4 tracking-[0.5px]">
          <Text variant="body-12">You have no current notifications.</Text>
        </div>
      ) : (
        data && (
          <div>
            <ul className="px-3 pb-2 divide-y flex flex-col">
              {notifications?.map(
                (notification: NotificationsResultFragment) => (
                  <Link
                    onClick={() => {
                      markRead(notification)
                      setOpen(false)
                    }}
                    to={pathForNotification(notification)}
                    key={notification.id}
                    className="block group px-5 hover:bg-hover cursor-pointer"
                  >
                    {renderNotification(notification)}
                  </Link>
                )
              )}
            </ul>
            <InfiniteLoadMore
              onEndReached={() => {
                if (data.notifications.pageInfo.endCursor) {
                  fetchMore({
                    variables: {
                      notificationsCursor:
                        data.notifications.pageInfo.endCursor,
                    },
                  })
                }
              }}
              canLoadMore={!loading && data.notifications.pageInfo.hasNextPage}
              loadingText=""
              loading={
                loading &&
                notifications.length > 0 &&
                data.notifications.pageInfo.hasNextPage
              }
              className="flex justify-center p-4"
            />
          </div>
        )
      )}
    </StyledDropdown>
  )
}

const NotificationWrapper = ({
  notification,
  children,
}: {
  notification: NotificationsResultFragment
  children: React.ReactNode
}) => {
  return (
    <li className="flex gap-2 py-3">
      <Text
        variant={notification.unread ? "body-12-medium" : "body-12"}
        className="leading-2"
      >
        {children}
      </Text>
      <div className="flex flex-col items-end space-y-1">
        <Text variant="body-10">
          {formatRelativeTime(notification.postedAt)}
        </Text>
        {notification.unread && (
          <div className="w-2 h-2 bg-foreground rounded-full"></div>
        )}
      </div>
    </li>
  )
}
